type FontSize = [
  fontSize: string,
  configuration: Partial<{
    lineHeight: string;
    letterSpacing: string;
    fontWeight: string | number;
  }>,
];

export const fontSizes: Record<string, FontSize> = {
  display: [
    '64px',
    {
      lineHeight: '72px',
      fontWeight: '400',
    },
  ],
  title: [
    '48px',
    {
      lineHeight: '56px',
      fontWeight: '400',
    },
  ],
  heading2: [
    '32px',
    {
      lineHeight: '40px',
      fontWeight: '400',
    },
  ],

  heading: [
    '24px',
    {
      lineHeight: '32px',
      fontWeight: '400',
    },
  ],
  'heading-semibold': [
    '24px',
    {
      lineHeight: '32px',
      fontWeight: '600',
    },
  ],
  'heading-light': [
    '24px',
    {
      lineHeight: '32px',
      fontWeight: '300',
    },
  ],

  subheading: [
    '18px',
    {
      lineHeight: '28px',
      fontWeight: '400',
    },
  ],
  'subheading-semibold': [
    '18px',
    {
      lineHeight: '28px',
      fontWeight: '600',
    },
  ],
  'subheading-light': [
    '18px',
    {
      lineHeight: '28px',
      fontWeight: '300',
    },
  ],

  'body-large': [
    '18px',
    {
      lineHeight: '24px',
      fontWeight: '400',
    },
  ],
  'body-large-semibold': [
    '18px',
    {
      lineHeight: '24px',
      fontWeight: '600',
    },
  ],
  'body-large-light': [
    '18px',
    {
      lineHeight: '24px',
      fontWeight: '300',
    },
  ],

  'body-small': [
    '14px',
    {
      lineHeight: '18px',
      fontWeight: '400',
    },
  ],
  'body-small-light': [
    '14px',
    {
      lineHeight: '18px',
      fontWeight: '300',
    },
  ],
  'body-small-semibold': [
    '14px',
    {
      lineHeight: '18px',
      fontWeight: '600',
    },
  ],

  body: [
    '16px',
    {
      lineHeight: '20px',
      fontWeight: '400',
    },
  ],
  'body-semibold': [
    '16px',
    {
      lineHeight: '20px',
      fontWeight: '600',
    },
  ],
  'body-light': [
    '16px',
    {
      lineHeight: '20px',
      fontWeight: '300',
    },
  ],

  caption: [
    '14px',
    {
      lineHeight: '16px',
      fontWeight: '400',
    },
  ],
  'caption-semibold': [
    '14px',
    {
      lineHeight: '16px',
      fontWeight: '600',
    },
  ],
  'caption-light': [
    '14px',
    {
      lineHeight: '16px',
      fontWeight: '300',
    },
  ],
};
