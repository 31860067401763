import { useTranslation } from 'react-i18next';
import { createFileRoute } from '@tanstack/react-router';

import { AgentsEmptyState } from 'components/agents/AgentsEmptyState';
import { AgentsList } from 'components/agents/AgentsList';
import { CreateAgentDialog } from 'components/agents/create/CreateAgentDialog';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { useFeatureFlag } from 'core/analytics';
import { agentsQueryOptions, useAgents } from 'core/modules/agents';

export const Route = createFileRoute('/_auth/_layout/agents/manage/')({
  component: RouteComponent,
  loader: ({ context }) => context.queryClient.ensureQueryData(agentsQueryOptions.agents()),
});

function RouteComponent() {
  const { t } = useTranslation();
  const isCreateCustomAgentsEnabled = useFeatureFlag('custom-agents');

  const { agents } = useAgents();

  return (
    <div className='flex h-full w-full flex-col justify-center'>
      <Content>
        <ContentHeader>
          <ContentHeader.Title>{t('agents.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t(isCreateCustomAgentsEnabled ? 'agents.subtitle_can_create_agents' : 'agents.subtitle')}</ContentHeader.SubTitle>
        </ContentHeader>
        <div className='flex h-full flex-col gap-8'>
          {isCreateCustomAgentsEnabled && <CreateAgentDialog />}
          {agents.length > 0 ? <AgentsList agents={agents} /> : <AgentsEmptyState />}
        </div>
      </Content>
    </div>
  );
}
