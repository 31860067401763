import { useEffect, useState } from 'react';

import { LocalStorageService } from '@legalfly/components/services/LocalStorageService';
import { setCssVariable } from '@legalfly/utils/cssVariable';
import { useScreenSize } from '@legalfly/utils/hooks/useDimensions';

const SMALL_SCREEN_BREAKPOINT = 1024;
const MIN_WIDTH = '80px';
const MAX_WIDTH = '250px';

function getStoredPreference() {
  const savedState = LocalStorageService.getItem('sidebarExpanded');
  return typeof savedState === 'boolean' ? savedState : null;
}

function shouldSidebarBeExpanded(windowWidth: number) {
  const storedPreference = getStoredPreference();
  const isSmallScreen = windowWidth <= SMALL_SCREEN_BREAKPOINT;

  if (storedPreference !== null) {
    // On small screens, always start closed regardless of preference
    return isSmallScreen ? false : storedPreference;
  }

  return !isSmallScreen;
}

export function useSidebar() {
  const { width: windowWidth } = useScreenSize();
  const [isExpanded, setIsExpanded] = useState(() => shouldSidebarBeExpanded(windowWidth));

  useEffect(() => {
    const isSmallScreen = windowWidth <= SMALL_SCREEN_BREAKPOINT;
    if (isSmallScreen) {
      setIsExpanded(false);
    } else {
      const storedPreference = getStoredPreference();
      setIsExpanded(storedPreference ?? true);
    }
  }, [windowWidth]);

  useEffect(() => {
    const width = isExpanded ? MAX_WIDTH : MIN_WIDTH;
    setCssVariable('sidebar-width', width);
  }, [isExpanded]);

  const handleExpandToggle = () => {
    const newState = !isExpanded;
    setIsExpanded(newState);
    LocalStorageService.setItem('sidebarExpanded', newState);
  };

  return {
    isExpanded,
    handleExpandToggle,
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
  };
}
