import { useTranslation } from 'react-i18next';
import { createFileRoute, Link, Outlet, redirect, useLocation } from '@tanstack/react-router';

import { Badge } from '@legalfly/ui/badge';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { cn } from '@legalfly/ui/utils';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { CopilotBackground } from 'components/conversations/copilot/CopilotBackground';
import { useFeatureFlag } from 'core/analytics';
import { configQueryOptions } from 'core/modules/config';

export const Route = createFileRoute('/_auth/_layout/translate/_layout')({
  beforeLoad: async ({ context }) => {
    const { flags } = await context.queryClient.ensureQueryData(configQueryOptions.config());
    if (!flags.includes('translate-text-agent') && !flags.includes('translate-file-agent')) {
      throw redirect({ to: '/' });
    }
  },
  component: TranslateLayoutRoute,
});

function TranslateLayoutRoute() {
  const { t } = useTranslation();
  const pathname = useLocation({ select: (location) => location.pathname });

  const translateTextAgentEnabled = useFeatureFlag('translate-text-agent');
  const translateFileAgentEnabled = useFeatureFlag('translate-file-agent');

  const isTextRoute = pathname.includes('/translate/text');
  const isFilesRoute = pathname.includes('/translate/files');

  return (
    <Content className='relative' innerClassName='overflow-auto'>
      <ContentHeader>
        <ContentHeader.Title className='flex items-center gap-2'>
          {t('nav.translation')}
          <Badge className='text-body-small text-content-body-constant-dark'>
            {t('translate.beta')}
          </Badge>
        </ContentHeader.Title>
        <ContentHeader.SubTitle>{t('translate.subTitle')}</ContentHeader.SubTitle>
      </ContentHeader>
      <CopilotBackground />

      <div className='mb-4 flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {translateTextAgentEnabled && (
            <Button
              variant='soft'
              renderLeft={<Icon name='translate-2' />}
              className={cn('bg-transparent', isTextRoute && 'bg-fill-strongest')}
              asChild
            >
              <Link to='/translate/text'>{t('translate.text.title')}</Link>
            </Button>
          )}
          {translateFileAgentEnabled && (
            <Button
              variant='soft'
              renderLeft={<Icon name='file-2' />}
              className={cn('bg-transparent', isFilesRoute && 'bg-fill-strongest')}
              asChild
            >
              <Link to='/translate/files'>{t('translate.files.title')}</Link>
            </Button>
          )}
        </div>
        {translateFileAgentEnabled && isFilesRoute && (
          <Button variant='soft' renderLeft={<Icon name='globe-1' />} asChild>
            <Link to='/translate/history'>{t('translate.history.history')}</Link>
          </Button>
        )}
      </div>

      <Outlet />
    </Content>
  );
}
