import deepmerge from '@fastify/deepmerge';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import type { ApiDraft, ApiDraftList, ApiTemplate, DraftingApi } from '@legalfly/api/drafting';

import { draftingQueryOptions } from './draftingQueryOptions';
import { initDraftingHelpers } from './helpers';

const merge = deepmerge({ all: true });

export const createDraftingModule = ({ draftingApi }: { draftingApi: DraftingApi }) => {
  const queryOptions = draftingQueryOptions({ draftingApi });
  const useDraftingHelpers = initDraftingHelpers(queryOptions);

  const useTemplates = (query: Parameters<DraftingApi['getDraftingTemplates']>[0] = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.templates(query));

    return {
      templates: data,
      isLoading,
      error,
    };
  };

  const useTemplate = ({ uuid }: { uuid: ApiTemplate['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.template({ uuid }));

    return {
      template: data,
      isLoading,
      error,
    };
  };

  const useCreateTemplate = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draftingTemplate', 'create'],
      mutationFn: draftingApi.createDraftingTemplate,
      onSuccess: (draftingTemplate) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.templates().queryKey.slice(0, 2) },
          (data: ApiTemplate[] | undefined) => {
            if (!data) {
              return [draftingTemplate];
            }

            return data.map((template) =>
              template.uuid === draftingTemplate.uuid ? draftingTemplate : template,
            );
          },
        );

        queryClient.setQueryData(
          queryOptions.template({ uuid: draftingTemplate.uuid }).queryKey,
          draftingTemplate,
        );
      },
    });

    return {
      createTemplate: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateTemplate = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'update'],
      mutationFn: draftingApi.updateDraftingTemplate,
      onSuccess: (updatedTemplate, { uuid }) => {
        queryClient.setQueryData(queryOptions.template({ uuid }).queryKey, updatedTemplate);

        queryClient.setQueriesData(
          { queryKey: queryOptions.templates().queryKey.slice(0, 2) },
          (templates: ApiTemplate[] | undefined) => {
            if (!templates) {
              return;
            }

            return templates.map((template) =>
              template.uuid === uuid ? updatedTemplate : template,
            );
          },
        );
      },
    });

    return {
      updateTemplate: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteTemplate = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'delete'],
      mutationFn: draftingApi.deleteDraftingTemplate,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.templates().queryKey.slice(0, 2) },
          (templates: ApiTemplate[] | undefined) => {
            if (!templates) {
              return;
            }

            return templates.filter((p) => p.uuid !== uuid);
          },
        );
        queryClient.removeQueries(queryOptions.template({ uuid }));
      },
    });

    return {
      deleteTemplate: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDrafts = (query: Parameters<DraftingApi['getDrafts']>[0] = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.drafts(query));

    return {
      drafts: data.drafts,
      stats: data.stats,
      isLoading,
      error,
    };
  };

  const useDraft = ({ uuid }: { uuid: ApiDraft['uuid'] }) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.draft({ uuid }));

    return {
      draft: data,
      isLoading,
      error,
    };
  };

  const useCreateDraft = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draftingDraft', 'create'],
      mutationFn: draftingApi.createDraft,
      onSuccess: (draftingDraft) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.drafts().queryKey.slice(0, 2) },
          (data: ApiDraftList | undefined) => {
            if (!data) return data;

            return {
              ...data,
              drafts: data.drafts.map((draft) =>
                draft.uuid === draftingDraft.uuid ? draftingDraft : draft,
              ),
            };
          },
        );
        queryClient.setQueryData(
          queryOptions.draft({ uuid: draftingDraft.uuid }).queryKey,
          draftingDraft,
        );
      },
    });

    return {
      createDraft: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateDraft = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draftingDraft', 'update'],
      mutationFn: draftingApi.updateDraft,
      onSuccess: (updatedDraft, { uuid }) => {
        queryClient.setQueryData(queryOptions.draft({ uuid }).queryKey, updatedDraft);
        queryClient.setQueriesData(
          { queryKey: queryOptions.drafts().queryKey.slice(0, 2) },
          (data: ApiDraftList | undefined) => {
            if (!data) return data;

            return {
              ...data,
              drafts: data.drafts.map((draft) =>
                draft.uuid === uuid ? merge(updatedDraft, draft) : draft,
              ),
            };
          },
        );
      },
    });

    return {
      updateDraft: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteDraft = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'delete'],
      mutationFn: draftingApi.deleteDraft,
      onSuccess: (_, { uuid }) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.drafts().queryKey.slice(0, 2) },
          (data: ApiDraftList | undefined) => {
            if (!data) return data;

            return {
              ...data,
              drafts: data.drafts.filter((draft) => draft.uuid !== uuid),
            };
          },
        );
        queryClient.removeQueries(queryOptions.draft({ uuid }));
      },
    });

    return {
      deleteDraft: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateTemplateClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'clause', 'create'],
      mutationFn: draftingApi.createTemplateClause,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.template({ uuid }));
      },
    });

    return {
      createClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateTemplateClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'clause', 'update'],
      mutationFn: draftingApi.updateTemplateClause,
      onSuccess: (updatedClause, { uuid, clauseUuid }) => {
        queryClient.setQueryData(queryOptions.template({ uuid }).queryKey, (template) => {
          if (!template) return template;

          return {
            ...template,
            clauses: template.clauses.map((clause) =>
              clause.uuid === clauseUuid ? merge(clause, updatedClause) : clause,
            ),
          };
        });
      },
    });

    return {
      updateClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useMoveTemplateClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'clause', 'move'],
      mutationFn: draftingApi.moveTemplateClause,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.template({ uuid }));
      },
    });

    return {
      moveClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteTemplateClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['template', 'clause', 'delete'],
      mutationFn: draftingApi.deleteTemplateClause,
      onSuccess: (_, { uuid, clauseUuid }) => {
        queryClient.setQueryData(queryOptions.template({ uuid }).queryKey, (template) => {
          if (!template) return template;

          return {
            ...template,
            clauses: template.clauses.filter((clause) => clause.uuid !== clauseUuid),
          };
        });
      },
    });

    return {
      deleteClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateDraftClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'clause', 'create'],
      mutationFn: draftingApi.createDraftClause,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.draft({ uuid }));
      },
    });

    return {
      createClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateDraftClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'clause', 'update'],
      mutationFn: draftingApi.updateDraftClause,
      onSuccess: (updatedClause, { uuid, clauseUuid }) => {
        queryClient.setQueryData(queryOptions.draft({ uuid }).queryKey, (draft) => {
          if (!draft) return draft;

          return {
            ...draft,
            clauses: draft.clauses.map((clause) =>
              clause.uuid === clauseUuid ? merge(clause, updatedClause) : clause,
            ),
          };
        });
      },
    });

    return {
      updateClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useMoveDraftClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'clause', 'move'],
      mutationFn: draftingApi.moveDraftClause,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.draft({ uuid }));
      },
    });

    return {
      moveClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteDraftClause = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'clause', 'delete'],
      mutationFn: draftingApi.deleteDraftClause,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.draft({ uuid }));
      },
    });

    return {
      deleteClause: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDownloadDraftAsPdf = () => {
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'download', 'pdf'],
      mutationFn: draftingApi.downloadDraftAsPdf,
    });

    return {
      downloadDraftAsPdf: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDownloadDraftAsDocx = () => {
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['draft', 'download', 'docx'],
      mutationFn: draftingApi.downloadDraftAsDocx,
    });

    return {
      downloadDraftAsDocx: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useTemplates,
    useTemplate,
    useCreateTemplate,
    useDeleteTemplate,
    useUpdateTemplate,
    useDrafts,
    useDraft,
    useCreateDraft,
    useUpdateDraft,
    useDeleteDraft,
    useCreateDraftClause,
    useUpdateDraftClause,
    useMoveDraftClause,
    useDeleteDraftClause,
    useCreateTemplateClause,
    useUpdateTemplateClause,
    useMoveTemplateClause,
    useDeleteTemplateClause,
    useDownloadDraftAsPdf,
    useDownloadDraftAsDocx,
    useDraftingHelpers,
    draftingQueryOptions: queryOptions,
  };
};
