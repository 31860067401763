import { legalFlyConfig } from '@legalfly/config';

import type { BodyOf, ParamsOf } from '../client';
import { createClient } from '../client';
import type { Fetcher } from '../fetcher/Fetcher';

export const createReportsApi = (fetcher: Fetcher) => {
  const client = createClient(fetcher);

  const api = legalFlyConfig.api.paths;

  const createWordReport = ({
    body,
    documentUuid,
  }: ParamsOf<'documents/:documentUuid/reports/word/post'> & {
    body: BodyOf<'documents/:documentUuid/reports/word/post'>;
  }) => {
    return client(api.documents.reports.word.create, {
      method: 'POST',
      body,
      params: {
        documentUuid,
      },
    });
  };

  const getWordReports = (params: ParamsOf<'documents/:documentUuid/reports/word/get'>) => {
    return client(api.documents.reports.word.list, {
      method: 'GET',
      params,
    });
  };

  const getWordReport = (
    params: ParamsOf<'documents/:documentUuid/reports/word/:reportUuid/get'>,
  ) => {
    return client(api.documents.reports.word.get, {
      method: 'GET',
      params,
    });
  };

  const downloadWordReport = ({
    reportUuid,
    documentUuid,
    body,
  }: ParamsOf<'documents/:documentUuid/reports/word/:reportUuid/download/post'> & {
    body: BodyOf<'documents/:documentUuid/reports/word/:reportUuid/download/post'>;
  }) => {
    return client(api.documents.reports.word.download, {
      method: 'POST',
      params: {
        documentUuid,
        reportUuid,
      },
      body,
    });
  };

  return {
    createWordReport,
    getWordReports,
    getWordReport,
    downloadWordReport,
  };
};

export type ReportsApi = ReturnType<typeof createReportsApi>;
