import type { BaseSorting } from '../core/types';

export enum ReviewStatus {
  RUNNING = 'running',
  IN_PROGRESS = 'in progress',
  ANOMALIES = 'anomalies',
  COMPLETED = 'completed',
}

export enum ReviewPlaybookItemStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

// Base interface
export type ReviewPlaybookItem = {
  type: 'plainText' | 'riskAssessment' | 'anomaly' | 'yesNo' | 'multipleChoice';
  title: string;
  text: string;
  snippetIds: string[];
  redrafts?: {
    snippetId: string;
    original: string;
    redraft: string;
    diff: string;
    reason: string;
  }[];
  followUpQuestions?: string[];
};

export interface ReviewPlaybookItemPlainText extends ReviewPlaybookItem {
  type: 'plainText';
}

export interface ReviewPlaybookItemRiskAssessment extends ReviewPlaybookItem {
  type: 'riskAssessment';
  level: 'neutral' | 'low' | 'medium' | 'high';
}

export interface ReviewPlaybookItemAnomaly extends ReviewPlaybookItem {
  type: 'anomaly';
  level: 'high';
}

export interface ReviewPlaybookItemYesNo extends ReviewPlaybookItem {
  type: 'yesNo';
  isYes: boolean;
}

export interface ReviewPlaybookItemMultipleChoice extends ReviewPlaybookItem {
  type: 'multipleChoice';
  selectedChoices: { color: string; option: string }[];
  reason: string;
}

export type ReviewPlaybookItemAnswer =
  | ReviewPlaybookItemPlainText
  | ReviewPlaybookItemRiskAssessment
  | ReviewPlaybookItemAnomaly
  | ReviewPlaybookItemYesNo
  | ReviewPlaybookItemMultipleChoice;

export const REVIEW_SORT_CONFIG = {
  createdAt: 'createdAt',
  status: 'status',
  category: 'document.category',
  owner: 'owner.firstName',
  jurisdiction: 'jurisdiction',
  name: 'name',
} as const;

export type ReviewSortField = keyof typeof REVIEW_SORT_CONFIG;
export type MappedReviewSortField = (typeof REVIEW_SORT_CONFIG)[ReviewSortField];

export const DEFAULT_REVIEW_SORT: BaseSorting<ReviewSortField> = {
  sortBy: 'createdAt',
  sortOrder: 'DESC',
} as const;

export type DefaultReviewSort = typeof DEFAULT_REVIEW_SORT;
