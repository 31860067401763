import type { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { FeedbackFeature } from '@legalfly/api/feedback';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@legalfly/ui/select';

type FeedbackFeatureKey = (typeof FeedbackFeature)[keyof typeof FeedbackFeature];

interface Props {
  value: FeedbackFeatureKey;
  onChange: (v: FeedbackFeatureKey) => void;
  triggerProps?: ComponentProps<typeof SelectTrigger>;
  contentProps?: ComponentProps<typeof SelectContent>;
}

const SelectFeedbackFeature = ({ value, onChange, triggerProps, contentProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger {...triggerProps}>
        <SelectValue placeholder={t('form.feedbackFeature.placeholder')}>
          {t(`feedback.feature.${value}`)}
        </SelectValue>
      </SelectTrigger>
      <SelectContent {...contentProps}>
        {Object.values(FeedbackFeature).map((key) => (
          <SelectItem key={key} value={key}>
            {t(`feedback.feature.${key}`)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectFeedbackFeature;
