import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiDocument } from '@legalfly/api/documents';
import { DocumentStatus } from '@legalfly/api/documents';
import { ProgressCard } from '@legalfly/ui/progress';
import { cn } from '@legalfly/ui/utils';

type DocumentCounts = Record<DocumentStatus, number>;

export function useDocumentStatusCount(documents: ApiDocument[]) {
  const documentCounts = useMemo(() => {
    const counts = Object.values(DocumentStatus).reduce((acc, status) => {
      acc[status] = 0;
      return acc;
    }, {} as DocumentCounts);

    documents.forEach((doc) => {
      if (doc.type === 'file' && doc.status in counts) {
        counts[doc.status as DocumentStatus]++;
      }
    });

    return counts;
  }, [documents]);

  const numberOfDocumentsProcessing = useMemo(() => {
    return Object.values(DocumentStatus).reduce((total, status) => {
      if (status !== DocumentStatus.COMPLETE && status !== DocumentStatus.ERROR) {
        return total + documentCounts[status];
      }
      return total;
    }, 0);
  }, [documentCounts]);

  return {
    documentCounts,
    numberOfDocumentsProcessing,
  };
}

export function useDocumentsUploadProgress(
  documents: ApiDocument[],
  numberOfFilesToUpload: number,
) {
  const [numberOfDocumentsToProcess, setNumberOfDocumentsToProcess] =
    useState(numberOfFilesToUpload);

  const { documentCounts, numberOfDocumentsProcessing } = useDocumentStatusCount(documents);

  const numberOfDocumentsCompleted = Math.max(
    0,
    numberOfDocumentsToProcess - numberOfDocumentsProcessing,
  );

  const isUploading = numberOfDocumentsToProcess > 0 && numberOfDocumentsProcessing > 0;

  /**
   * When we would refresh the page, we set the number of documents to process to the number of documents processing
   */
  useEffect(() => {
    if (numberOfDocumentsToProcess === 0 && numberOfDocumentsProcessing > 0) {
      setNumberOfDocumentsToProcess(numberOfDocumentsProcessing);
    }

    if (numberOfFilesToUpload > 0) {
      setNumberOfDocumentsToProcess(numberOfFilesToUpload);
    }
  }, [numberOfDocumentsToProcess, numberOfDocumentsProcessing, numberOfFilesToUpload]);

  return {
    numberOfDocumentsToProcess,
    documentCounts,
    numberOfDocumentsCompleted,
    isUploading,
  };
}

type Props = ReturnType<typeof useDocumentsUploadProgress> & {
  className?: string;
};

export function DocumentsUploadProgress({
  numberOfDocumentsToProcess,
  documentCounts,
  numberOfDocumentsCompleted,
  isUploading,
  className,
}: Props) {
  const { t } = useTranslation('components');

  const progress = useMemo(() => {
    if (documentCounts[DocumentStatus.PENDING] > 0) {
      return 1;
    }

    if (documentCounts[DocumentStatus.ANONYMIZING] > 0) {
      return 25;
    }

    if (documentCounts[DocumentStatus.VECTORIZING] > 0) {
      return 50;
    }

    if (documentCounts[DocumentStatus.DETERMINING_DOCUMENT_PROPERTIES] > 0) {
      return 75;
    }

    return 100;
  }, [documentCounts]);

  const subtitle = useMemo(() => {
    if (documentCounts[DocumentStatus.PENDING] > 0) {
      return t('documents.progress.subtitle.upload', {
        count: numberOfDocumentsToProcess,
      });
    }

    if (documentCounts[DocumentStatus.ANONYMIZING] > 0) {
      return t('documents.progress.subtitle.anonymisation', {
        count: numberOfDocumentsToProcess,
      });
    }

    if (documentCounts[DocumentStatus.VECTORIZING] > 0) {
      return t('documents.progress.subtitle.vectorisation');
    }

    return t('documents.progress.subtitle.document_properties');
  }, [documentCounts, numberOfDocumentsToProcess, t]);

  if (!isUploading) {
    return null;
  }

  return (
    <ProgressCard
      className={cn('animate-in slide-in-from-bottom', className)}
      title={t('documents.progress.title')}
      subTitle={subtitle}
      action={`${numberOfDocumentsCompleted}/${numberOfDocumentsToProcess}`}
    >
      <ProgressCard.Bar value={progress} />
    </ProgressCard>
  );
}
