/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router';

// Import Routes

import { Route as rootRoute } from './../routes/__root';
import { Route as AuthImport } from './../routes/_auth';
import { Route as AuthLayoutImport } from './../routes/_auth/_layout';
import { Route as AuthLayoutIndexImport } from './../routes/_auth/_layout/index';
import { Route as AuthAuthErrorImport } from './../routes/_auth/auth.error';
import { Route as AuthLayoutReviewIndexImport } from './../routes/_auth/_layout/review/index';
import { Route as AuthLayoutPlaybooksIndexImport } from './../routes/_auth/_layout/playbooks/index';
import { Route as AuthLayoutMultiReviewIndexImport } from './../routes/_auth/_layout/multi-review/index';
import { Route as AuthLayoutDiscoveryIndexImport } from './../routes/_auth/_layout/discovery/index';
import { Route as AuthLayoutArchiveIndexImport } from './../routes/_auth/_layout/archive/index';
import { Route as AuthLayoutAnonymisationIndexImport } from './../routes/_auth/_layout/anonymisation/index';
import { Route as AuthLayoutTranslateLayoutImport } from './../routes/_auth/_layout/translate/_layout';
import { Route as AuthLayoutSettingsUsersImport } from './../routes/_auth/_layout/settings/users';
import { Route as AuthLayoutSettingsProfileImport } from './../routes/_auth/_layout/settings/profile';
import { Route as AuthLayoutReviewNewImport } from './../routes/_auth/_layout/review/new';
import { Route as AuthLayoutReviewUuidImport } from './../routes/_auth/_layout/review/$uuid';
import { Route as AuthLayoutMultiReviewUuidImport } from './../routes/_auth/_layout/multi-review/$uuid';
import { Route as AuthLayoutDiscoveryUuidImport } from './../routes/_auth/_layout/discovery/$uuid';
import { Route as AuthLayoutAnonymisationUuidImport } from './../routes/_auth/_layout/anonymisation/$uuid';
import { Route as AuthLayoutTranslateHistoryIndexImport } from './../routes/_auth/_layout/translate/history/index';
import { Route as AuthLayoutReviewStartIndexImport } from './../routes/_auth/_layout/review/start/index';
import { Route as AuthLayoutPlaybooksPlaybookUuidIndexImport } from './../routes/_auth/_layout/playbooks/$playbookUuid/index';
import { Route as AuthLayoutMultiReviewStartIndexImport } from './../routes/_auth/_layout/multi-review/start/index';
import { Route as AuthLayoutDraftingTemplatesIndexImport } from './../routes/_auth/_layout/drafting/templates/index';
import { Route as AuthLayoutDraftingDraftsIndexImport } from './../routes/_auth/_layout/drafting/drafts/index';
import { Route as AuthLayoutDocumentsFolderIndexImport } from './../routes/_auth/_layout/documents/folder/index';
import { Route as AuthLayoutAgentsManageIndexImport } from './../routes/_auth/_layout/agents/manage/index';
import { Route as AuthLayoutAgentsAgentUuidIndexImport } from './../routes/_auth/_layout/agents/$agentUuid/index';
import { Route as AuthLayoutReviewStartUuidImport } from './../routes/_auth/_layout/review/start/$uuid';
import { Route as AuthLayoutPlaybooksPlaybookUuidBulkItemsImport } from './../routes/_auth/_layout/playbooks/$playbookUuid/bulk-items';
import { Route as AuthLayoutMultiReviewStartUuidImport } from './../routes/_auth/_layout/multi-review/start/$uuid';
import { Route as AuthLayoutDraftingTemplatesUuidImport } from './../routes/_auth/_layout/drafting/templates/$uuid';
import { Route as AuthLayoutDraftingDraftsChooseTemplateImport } from './../routes/_auth/_layout/drafting/drafts/choose-template';
import { Route as AuthLayoutDraftingDraftsUuidImport } from './../routes/_auth/_layout/drafting/drafts/$uuid';
import { Route as AuthLayoutDocumentsFolderUuidImport } from './../routes/_auth/_layout/documents/folder/$uuid';
import { Route as AuthLayoutDocumentsFileUuidImport } from './../routes/_auth/_layout/documents/file/$uuid';
import { Route as AuthLayoutAnonymisationFileUuidImport } from './../routes/_auth/_layout/anonymisation/file/$uuid';
import { Route as AuthLayoutAgentsManageAgentUuidImport } from './../routes/_auth/_layout/agents/manage/$agentUuid';
import { Route as AuthLayoutTranslateLayoutTextIndexImport } from './../routes/_auth/_layout/translate/_layout/text/index';
import { Route as AuthLayoutTranslateLayoutFilesIndexImport } from './../routes/_auth/_layout/translate/_layout/files/index';
import { Route as AuthLayoutReportsWordDocumentUuidReportUuidImport } from './../routes/_auth/_layout/reports/word/$documentUuid/$reportUuid';
import { Route as AuthLayoutAgentsAgentUuidConversationsConversationUuidImport } from './../routes/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid';
import { Route as AuthLayoutTranslateLayoutFilesGroupUuidImport } from './../routes/_auth/_layout/translate/_layout/files/group/$uuid';

// Create Virtual Routes

const AuthLayoutTranslateImport = createFileRoute('/_auth/_layout/translate')();

// Create/Update Routes

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any);

const AuthLayoutRoute = AuthLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => AuthRoute,
} as any);

const AuthLayoutTranslateRoute = AuthLayoutTranslateImport.update({
  id: '/translate',
  path: '/translate',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutIndexRoute = AuthLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthAuthErrorRoute = AuthAuthErrorImport.update({
  id: '/auth/error',
  path: '/auth/error',
  getParentRoute: () => AuthRoute,
} as any);

const AuthLayoutReviewIndexRoute = AuthLayoutReviewIndexImport.update({
  id: '/review/',
  path: '/review/',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutPlaybooksIndexRoute = AuthLayoutPlaybooksIndexImport.update({
  id: '/playbooks/',
  path: '/playbooks/',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutMultiReviewIndexRoute = AuthLayoutMultiReviewIndexImport.update(
  {
    id: '/multi-review/',
    path: '/multi-review/',
    getParentRoute: () => AuthLayoutRoute,
  } as any,
);

const AuthLayoutDiscoveryIndexRoute = AuthLayoutDiscoveryIndexImport.update({
  id: '/discovery/',
  path: '/discovery/',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutArchiveIndexRoute = AuthLayoutArchiveIndexImport.update({
  id: '/archive/',
  path: '/archive/',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutAnonymisationIndexRoute =
  AuthLayoutAnonymisationIndexImport.update({
    id: '/anonymisation/',
    path: '/anonymisation/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutTranslateLayoutRoute = AuthLayoutTranslateLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => AuthLayoutTranslateRoute,
} as any);

const AuthLayoutSettingsUsersRoute = AuthLayoutSettingsUsersImport.update({
  id: '/settings/users',
  path: '/settings/users',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutSettingsProfileRoute = AuthLayoutSettingsProfileImport.update({
  id: '/settings/profile',
  path: '/settings/profile',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutReviewNewRoute = AuthLayoutReviewNewImport.update({
  id: '/review/new',
  path: '/review/new',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutReviewUuidRoute = AuthLayoutReviewUuidImport.update({
  id: '/review/$uuid',
  path: '/review/$uuid',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutMultiReviewUuidRoute = AuthLayoutMultiReviewUuidImport.update({
  id: '/multi-review/$uuid',
  path: '/multi-review/$uuid',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutDiscoveryUuidRoute = AuthLayoutDiscoveryUuidImport.update({
  id: '/discovery/$uuid',
  path: '/discovery/$uuid',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutAnonymisationUuidRoute =
  AuthLayoutAnonymisationUuidImport.update({
    id: '/anonymisation/$uuid',
    path: '/anonymisation/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutTranslateHistoryIndexRoute =
  AuthLayoutTranslateHistoryIndexImport.update({
    id: '/history/',
    path: '/history/',
    getParentRoute: () => AuthLayoutTranslateRoute,
  } as any);

const AuthLayoutReviewStartIndexRoute = AuthLayoutReviewStartIndexImport.update(
  {
    id: '/review/start/',
    path: '/review/start/',
    getParentRoute: () => AuthLayoutRoute,
  } as any,
);

const AuthLayoutPlaybooksPlaybookUuidIndexRoute =
  AuthLayoutPlaybooksPlaybookUuidIndexImport.update({
    id: '/playbooks/$playbookUuid/',
    path: '/playbooks/$playbookUuid/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutMultiReviewStartIndexRoute =
  AuthLayoutMultiReviewStartIndexImport.update({
    id: '/multi-review/start/',
    path: '/multi-review/start/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDraftingTemplatesIndexRoute =
  AuthLayoutDraftingTemplatesIndexImport.update({
    id: '/drafting/templates/',
    path: '/drafting/templates/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDraftingDraftsIndexRoute =
  AuthLayoutDraftingDraftsIndexImport.update({
    id: '/drafting/drafts/',
    path: '/drafting/drafts/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDocumentsFolderIndexRoute =
  AuthLayoutDocumentsFolderIndexImport.update({
    id: '/documents/folder/',
    path: '/documents/folder/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutAgentsManageIndexRoute =
  AuthLayoutAgentsManageIndexImport.update({
    id: '/agents/manage/',
    path: '/agents/manage/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutAgentsAgentUuidIndexRoute =
  AuthLayoutAgentsAgentUuidIndexImport.update({
    id: '/agents/$agentUuid/',
    path: '/agents/$agentUuid/',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutReviewStartUuidRoute = AuthLayoutReviewStartUuidImport.update({
  id: '/review/start/$uuid',
  path: '/review/start/$uuid',
  getParentRoute: () => AuthLayoutRoute,
} as any);

const AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute =
  AuthLayoutPlaybooksPlaybookUuidBulkItemsImport.update({
    id: '/playbooks/$playbookUuid/bulk-items',
    path: '/playbooks/$playbookUuid/bulk-items',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutMultiReviewStartUuidRoute =
  AuthLayoutMultiReviewStartUuidImport.update({
    id: '/multi-review/start/$uuid',
    path: '/multi-review/start/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDraftingTemplatesUuidRoute =
  AuthLayoutDraftingTemplatesUuidImport.update({
    id: '/drafting/templates/$uuid',
    path: '/drafting/templates/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDraftingDraftsChooseTemplateRoute =
  AuthLayoutDraftingDraftsChooseTemplateImport.update({
    id: '/drafting/drafts/choose-template',
    path: '/drafting/drafts/choose-template',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDraftingDraftsUuidRoute =
  AuthLayoutDraftingDraftsUuidImport.update({
    id: '/drafting/drafts/$uuid',
    path: '/drafting/drafts/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDocumentsFolderUuidRoute =
  AuthLayoutDocumentsFolderUuidImport.update({
    id: '/documents/folder/$uuid',
    path: '/documents/folder/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutDocumentsFileUuidRoute =
  AuthLayoutDocumentsFileUuidImport.update({
    id: '/documents/file/$uuid',
    path: '/documents/file/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutAnonymisationFileUuidRoute =
  AuthLayoutAnonymisationFileUuidImport.update({
    id: '/anonymisation/file/$uuid',
    path: '/anonymisation/file/$uuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutAgentsManageAgentUuidRoute =
  AuthLayoutAgentsManageAgentUuidImport.update({
    id: '/agents/manage/$agentUuid',
    path: '/agents/manage/$agentUuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutTranslateLayoutTextIndexRoute =
  AuthLayoutTranslateLayoutTextIndexImport.update({
    id: '/text/',
    path: '/text/',
    getParentRoute: () => AuthLayoutTranslateLayoutRoute,
  } as any);

const AuthLayoutTranslateLayoutFilesIndexRoute =
  AuthLayoutTranslateLayoutFilesIndexImport.update({
    id: '/files/',
    path: '/files/',
    getParentRoute: () => AuthLayoutTranslateLayoutRoute,
  } as any);

const AuthLayoutReportsWordDocumentUuidReportUuidRoute =
  AuthLayoutReportsWordDocumentUuidReportUuidImport.update({
    id: '/reports/word/$documentUuid/$reportUuid',
    path: '/reports/word/$documentUuid/$reportUuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute =
  AuthLayoutAgentsAgentUuidConversationsConversationUuidImport.update({
    id: '/agents/$agentUuid/conversations/$conversationUuid',
    path: '/agents/$agentUuid/conversations/$conversationUuid',
    getParentRoute: () => AuthLayoutRoute,
  } as any);

const AuthLayoutTranslateLayoutFilesGroupUuidRoute =
  AuthLayoutTranslateLayoutFilesGroupUuidImport.update({
    id: '/files/group/$uuid',
    path: '/files/group/$uuid',
    getParentRoute: () => AuthLayoutTranslateLayoutRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthImport;
      parentRoute: typeof rootRoute;
    };
    '/_auth/_layout': {
      id: '/_auth/_layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthLayoutImport;
      parentRoute: typeof AuthImport;
    };
    '/_auth/auth/error': {
      id: '/_auth/auth/error';
      path: '/auth/error';
      fullPath: '/auth/error';
      preLoaderRoute: typeof AuthAuthErrorImport;
      parentRoute: typeof AuthImport;
    };
    '/_auth/_layout/': {
      id: '/_auth/_layout/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof AuthLayoutIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/anonymisation/$uuid': {
      id: '/_auth/_layout/anonymisation/$uuid';
      path: '/anonymisation/$uuid';
      fullPath: '/anonymisation/$uuid';
      preLoaderRoute: typeof AuthLayoutAnonymisationUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/discovery/$uuid': {
      id: '/_auth/_layout/discovery/$uuid';
      path: '/discovery/$uuid';
      fullPath: '/discovery/$uuid';
      preLoaderRoute: typeof AuthLayoutDiscoveryUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/multi-review/$uuid': {
      id: '/_auth/_layout/multi-review/$uuid';
      path: '/multi-review/$uuid';
      fullPath: '/multi-review/$uuid';
      preLoaderRoute: typeof AuthLayoutMultiReviewUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/review/$uuid': {
      id: '/_auth/_layout/review/$uuid';
      path: '/review/$uuid';
      fullPath: '/review/$uuid';
      preLoaderRoute: typeof AuthLayoutReviewUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/review/new': {
      id: '/_auth/_layout/review/new';
      path: '/review/new';
      fullPath: '/review/new';
      preLoaderRoute: typeof AuthLayoutReviewNewImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/settings/profile': {
      id: '/_auth/_layout/settings/profile';
      path: '/settings/profile';
      fullPath: '/settings/profile';
      preLoaderRoute: typeof AuthLayoutSettingsProfileImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/settings/users': {
      id: '/_auth/_layout/settings/users';
      path: '/settings/users';
      fullPath: '/settings/users';
      preLoaderRoute: typeof AuthLayoutSettingsUsersImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/translate': {
      id: '/_auth/_layout/translate';
      path: '/translate';
      fullPath: '/translate';
      preLoaderRoute: typeof AuthLayoutTranslateImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/translate/_layout': {
      id: '/_auth/_layout/translate/_layout';
      path: '/translate';
      fullPath: '/translate';
      preLoaderRoute: typeof AuthLayoutTranslateLayoutImport;
      parentRoute: typeof AuthLayoutTranslateRoute;
    };
    '/_auth/_layout/anonymisation/': {
      id: '/_auth/_layout/anonymisation/';
      path: '/anonymisation';
      fullPath: '/anonymisation';
      preLoaderRoute: typeof AuthLayoutAnonymisationIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/archive/': {
      id: '/_auth/_layout/archive/';
      path: '/archive';
      fullPath: '/archive';
      preLoaderRoute: typeof AuthLayoutArchiveIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/discovery/': {
      id: '/_auth/_layout/discovery/';
      path: '/discovery';
      fullPath: '/discovery';
      preLoaderRoute: typeof AuthLayoutDiscoveryIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/multi-review/': {
      id: '/_auth/_layout/multi-review/';
      path: '/multi-review';
      fullPath: '/multi-review';
      preLoaderRoute: typeof AuthLayoutMultiReviewIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/playbooks/': {
      id: '/_auth/_layout/playbooks/';
      path: '/playbooks';
      fullPath: '/playbooks';
      preLoaderRoute: typeof AuthLayoutPlaybooksIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/review/': {
      id: '/_auth/_layout/review/';
      path: '/review';
      fullPath: '/review';
      preLoaderRoute: typeof AuthLayoutReviewIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/agents/manage/$agentUuid': {
      id: '/_auth/_layout/agents/manage/$agentUuid';
      path: '/agents/manage/$agentUuid';
      fullPath: '/agents/manage/$agentUuid';
      preLoaderRoute: typeof AuthLayoutAgentsManageAgentUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/anonymisation/file/$uuid': {
      id: '/_auth/_layout/anonymisation/file/$uuid';
      path: '/anonymisation/file/$uuid';
      fullPath: '/anonymisation/file/$uuid';
      preLoaderRoute: typeof AuthLayoutAnonymisationFileUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/documents/file/$uuid': {
      id: '/_auth/_layout/documents/file/$uuid';
      path: '/documents/file/$uuid';
      fullPath: '/documents/file/$uuid';
      preLoaderRoute: typeof AuthLayoutDocumentsFileUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/documents/folder/$uuid': {
      id: '/_auth/_layout/documents/folder/$uuid';
      path: '/documents/folder/$uuid';
      fullPath: '/documents/folder/$uuid';
      preLoaderRoute: typeof AuthLayoutDocumentsFolderUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/drafting/drafts/$uuid': {
      id: '/_auth/_layout/drafting/drafts/$uuid';
      path: '/drafting/drafts/$uuid';
      fullPath: '/drafting/drafts/$uuid';
      preLoaderRoute: typeof AuthLayoutDraftingDraftsUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/drafting/drafts/choose-template': {
      id: '/_auth/_layout/drafting/drafts/choose-template';
      path: '/drafting/drafts/choose-template';
      fullPath: '/drafting/drafts/choose-template';
      preLoaderRoute: typeof AuthLayoutDraftingDraftsChooseTemplateImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/drafting/templates/$uuid': {
      id: '/_auth/_layout/drafting/templates/$uuid';
      path: '/drafting/templates/$uuid';
      fullPath: '/drafting/templates/$uuid';
      preLoaderRoute: typeof AuthLayoutDraftingTemplatesUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/multi-review/start/$uuid': {
      id: '/_auth/_layout/multi-review/start/$uuid';
      path: '/multi-review/start/$uuid';
      fullPath: '/multi-review/start/$uuid';
      preLoaderRoute: typeof AuthLayoutMultiReviewStartUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/playbooks/$playbookUuid/bulk-items': {
      id: '/_auth/_layout/playbooks/$playbookUuid/bulk-items';
      path: '/playbooks/$playbookUuid/bulk-items';
      fullPath: '/playbooks/$playbookUuid/bulk-items';
      preLoaderRoute: typeof AuthLayoutPlaybooksPlaybookUuidBulkItemsImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/review/start/$uuid': {
      id: '/_auth/_layout/review/start/$uuid';
      path: '/review/start/$uuid';
      fullPath: '/review/start/$uuid';
      preLoaderRoute: typeof AuthLayoutReviewStartUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/agents/$agentUuid/': {
      id: '/_auth/_layout/agents/$agentUuid/';
      path: '/agents/$agentUuid';
      fullPath: '/agents/$agentUuid';
      preLoaderRoute: typeof AuthLayoutAgentsAgentUuidIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/agents/manage/': {
      id: '/_auth/_layout/agents/manage/';
      path: '/agents/manage';
      fullPath: '/agents/manage';
      preLoaderRoute: typeof AuthLayoutAgentsManageIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/documents/folder/': {
      id: '/_auth/_layout/documents/folder/';
      path: '/documents/folder';
      fullPath: '/documents/folder';
      preLoaderRoute: typeof AuthLayoutDocumentsFolderIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/drafting/drafts/': {
      id: '/_auth/_layout/drafting/drafts/';
      path: '/drafting/drafts';
      fullPath: '/drafting/drafts';
      preLoaderRoute: typeof AuthLayoutDraftingDraftsIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/drafting/templates/': {
      id: '/_auth/_layout/drafting/templates/';
      path: '/drafting/templates';
      fullPath: '/drafting/templates';
      preLoaderRoute: typeof AuthLayoutDraftingTemplatesIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/multi-review/start/': {
      id: '/_auth/_layout/multi-review/start/';
      path: '/multi-review/start';
      fullPath: '/multi-review/start';
      preLoaderRoute: typeof AuthLayoutMultiReviewStartIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/playbooks/$playbookUuid/': {
      id: '/_auth/_layout/playbooks/$playbookUuid/';
      path: '/playbooks/$playbookUuid';
      fullPath: '/playbooks/$playbookUuid';
      preLoaderRoute: typeof AuthLayoutPlaybooksPlaybookUuidIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/review/start/': {
      id: '/_auth/_layout/review/start/';
      path: '/review/start';
      fullPath: '/review/start';
      preLoaderRoute: typeof AuthLayoutReviewStartIndexImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/translate/history/': {
      id: '/_auth/_layout/translate/history/';
      path: '/history';
      fullPath: '/translate/history';
      preLoaderRoute: typeof AuthLayoutTranslateHistoryIndexImport;
      parentRoute: typeof AuthLayoutTranslateImport;
    };
    '/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid': {
      id: '/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid';
      path: '/agents/$agentUuid/conversations/$conversationUuid';
      fullPath: '/agents/$agentUuid/conversations/$conversationUuid';
      preLoaderRoute: typeof AuthLayoutAgentsAgentUuidConversationsConversationUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/reports/word/$documentUuid/$reportUuid': {
      id: '/_auth/_layout/reports/word/$documentUuid/$reportUuid';
      path: '/reports/word/$documentUuid/$reportUuid';
      fullPath: '/reports/word/$documentUuid/$reportUuid';
      preLoaderRoute: typeof AuthLayoutReportsWordDocumentUuidReportUuidImport;
      parentRoute: typeof AuthLayoutImport;
    };
    '/_auth/_layout/translate/_layout/files/': {
      id: '/_auth/_layout/translate/_layout/files/';
      path: '/files';
      fullPath: '/translate/files';
      preLoaderRoute: typeof AuthLayoutTranslateLayoutFilesIndexImport;
      parentRoute: typeof AuthLayoutTranslateLayoutImport;
    };
    '/_auth/_layout/translate/_layout/text/': {
      id: '/_auth/_layout/translate/_layout/text/';
      path: '/text';
      fullPath: '/translate/text';
      preLoaderRoute: typeof AuthLayoutTranslateLayoutTextIndexImport;
      parentRoute: typeof AuthLayoutTranslateLayoutImport;
    };
    '/_auth/_layout/translate/_layout/files/group/$uuid': {
      id: '/_auth/_layout/translate/_layout/files/group/$uuid';
      path: '/files/group/$uuid';
      fullPath: '/translate/files/group/$uuid';
      preLoaderRoute: typeof AuthLayoutTranslateLayoutFilesGroupUuidImport;
      parentRoute: typeof AuthLayoutTranslateLayoutImport;
    };
  }
}

// Create and export the route tree

interface AuthLayoutTranslateLayoutRouteChildren {
  AuthLayoutTranslateLayoutFilesIndexRoute: typeof AuthLayoutTranslateLayoutFilesIndexRoute;
  AuthLayoutTranslateLayoutTextIndexRoute: typeof AuthLayoutTranslateLayoutTextIndexRoute;
  AuthLayoutTranslateLayoutFilesGroupUuidRoute: typeof AuthLayoutTranslateLayoutFilesGroupUuidRoute;
}

const AuthLayoutTranslateLayoutRouteChildren: AuthLayoutTranslateLayoutRouteChildren =
  {
    AuthLayoutTranslateLayoutFilesIndexRoute:
      AuthLayoutTranslateLayoutFilesIndexRoute,
    AuthLayoutTranslateLayoutTextIndexRoute:
      AuthLayoutTranslateLayoutTextIndexRoute,
    AuthLayoutTranslateLayoutFilesGroupUuidRoute:
      AuthLayoutTranslateLayoutFilesGroupUuidRoute,
  };

const AuthLayoutTranslateLayoutRouteWithChildren =
  AuthLayoutTranslateLayoutRoute._addFileChildren(
    AuthLayoutTranslateLayoutRouteChildren,
  );

interface AuthLayoutTranslateRouteChildren {
  AuthLayoutTranslateLayoutRoute: typeof AuthLayoutTranslateLayoutRouteWithChildren;
  AuthLayoutTranslateHistoryIndexRoute: typeof AuthLayoutTranslateHistoryIndexRoute;
}

const AuthLayoutTranslateRouteChildren: AuthLayoutTranslateRouteChildren = {
  AuthLayoutTranslateLayoutRoute: AuthLayoutTranslateLayoutRouteWithChildren,
  AuthLayoutTranslateHistoryIndexRoute: AuthLayoutTranslateHistoryIndexRoute,
};

const AuthLayoutTranslateRouteWithChildren =
  AuthLayoutTranslateRoute._addFileChildren(AuthLayoutTranslateRouteChildren);

interface AuthLayoutRouteChildren {
  AuthLayoutIndexRoute: typeof AuthLayoutIndexRoute;
  AuthLayoutAnonymisationUuidRoute: typeof AuthLayoutAnonymisationUuidRoute;
  AuthLayoutDiscoveryUuidRoute: typeof AuthLayoutDiscoveryUuidRoute;
  AuthLayoutMultiReviewUuidRoute: typeof AuthLayoutMultiReviewUuidRoute;
  AuthLayoutReviewUuidRoute: typeof AuthLayoutReviewUuidRoute;
  AuthLayoutReviewNewRoute: typeof AuthLayoutReviewNewRoute;
  AuthLayoutSettingsProfileRoute: typeof AuthLayoutSettingsProfileRoute;
  AuthLayoutSettingsUsersRoute: typeof AuthLayoutSettingsUsersRoute;
  AuthLayoutTranslateRoute: typeof AuthLayoutTranslateRouteWithChildren;
  AuthLayoutAnonymisationIndexRoute: typeof AuthLayoutAnonymisationIndexRoute;
  AuthLayoutArchiveIndexRoute: typeof AuthLayoutArchiveIndexRoute;
  AuthLayoutDiscoveryIndexRoute: typeof AuthLayoutDiscoveryIndexRoute;
  AuthLayoutMultiReviewIndexRoute: typeof AuthLayoutMultiReviewIndexRoute;
  AuthLayoutPlaybooksIndexRoute: typeof AuthLayoutPlaybooksIndexRoute;
  AuthLayoutReviewIndexRoute: typeof AuthLayoutReviewIndexRoute;
  AuthLayoutAgentsManageAgentUuidRoute: typeof AuthLayoutAgentsManageAgentUuidRoute;
  AuthLayoutAnonymisationFileUuidRoute: typeof AuthLayoutAnonymisationFileUuidRoute;
  AuthLayoutDocumentsFileUuidRoute: typeof AuthLayoutDocumentsFileUuidRoute;
  AuthLayoutDocumentsFolderUuidRoute: typeof AuthLayoutDocumentsFolderUuidRoute;
  AuthLayoutDraftingDraftsUuidRoute: typeof AuthLayoutDraftingDraftsUuidRoute;
  AuthLayoutDraftingDraftsChooseTemplateRoute: typeof AuthLayoutDraftingDraftsChooseTemplateRoute;
  AuthLayoutDraftingTemplatesUuidRoute: typeof AuthLayoutDraftingTemplatesUuidRoute;
  AuthLayoutMultiReviewStartUuidRoute: typeof AuthLayoutMultiReviewStartUuidRoute;
  AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute: typeof AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute;
  AuthLayoutReviewStartUuidRoute: typeof AuthLayoutReviewStartUuidRoute;
  AuthLayoutAgentsAgentUuidIndexRoute: typeof AuthLayoutAgentsAgentUuidIndexRoute;
  AuthLayoutAgentsManageIndexRoute: typeof AuthLayoutAgentsManageIndexRoute;
  AuthLayoutDocumentsFolderIndexRoute: typeof AuthLayoutDocumentsFolderIndexRoute;
  AuthLayoutDraftingDraftsIndexRoute: typeof AuthLayoutDraftingDraftsIndexRoute;
  AuthLayoutDraftingTemplatesIndexRoute: typeof AuthLayoutDraftingTemplatesIndexRoute;
  AuthLayoutMultiReviewStartIndexRoute: typeof AuthLayoutMultiReviewStartIndexRoute;
  AuthLayoutPlaybooksPlaybookUuidIndexRoute: typeof AuthLayoutPlaybooksPlaybookUuidIndexRoute;
  AuthLayoutReviewStartIndexRoute: typeof AuthLayoutReviewStartIndexRoute;
  AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute: typeof AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute;
  AuthLayoutReportsWordDocumentUuidReportUuidRoute: typeof AuthLayoutReportsWordDocumentUuidReportUuidRoute;
}

const AuthLayoutRouteChildren: AuthLayoutRouteChildren = {
  AuthLayoutIndexRoute: AuthLayoutIndexRoute,
  AuthLayoutAnonymisationUuidRoute: AuthLayoutAnonymisationUuidRoute,
  AuthLayoutDiscoveryUuidRoute: AuthLayoutDiscoveryUuidRoute,
  AuthLayoutMultiReviewUuidRoute: AuthLayoutMultiReviewUuidRoute,
  AuthLayoutReviewUuidRoute: AuthLayoutReviewUuidRoute,
  AuthLayoutReviewNewRoute: AuthLayoutReviewNewRoute,
  AuthLayoutSettingsProfileRoute: AuthLayoutSettingsProfileRoute,
  AuthLayoutSettingsUsersRoute: AuthLayoutSettingsUsersRoute,
  AuthLayoutTranslateRoute: AuthLayoutTranslateRouteWithChildren,
  AuthLayoutAnonymisationIndexRoute: AuthLayoutAnonymisationIndexRoute,
  AuthLayoutArchiveIndexRoute: AuthLayoutArchiveIndexRoute,
  AuthLayoutDiscoveryIndexRoute: AuthLayoutDiscoveryIndexRoute,
  AuthLayoutMultiReviewIndexRoute: AuthLayoutMultiReviewIndexRoute,
  AuthLayoutPlaybooksIndexRoute: AuthLayoutPlaybooksIndexRoute,
  AuthLayoutReviewIndexRoute: AuthLayoutReviewIndexRoute,
  AuthLayoutAgentsManageAgentUuidRoute: AuthLayoutAgentsManageAgentUuidRoute,
  AuthLayoutAnonymisationFileUuidRoute: AuthLayoutAnonymisationFileUuidRoute,
  AuthLayoutDocumentsFileUuidRoute: AuthLayoutDocumentsFileUuidRoute,
  AuthLayoutDocumentsFolderUuidRoute: AuthLayoutDocumentsFolderUuidRoute,
  AuthLayoutDraftingDraftsUuidRoute: AuthLayoutDraftingDraftsUuidRoute,
  AuthLayoutDraftingDraftsChooseTemplateRoute:
    AuthLayoutDraftingDraftsChooseTemplateRoute,
  AuthLayoutDraftingTemplatesUuidRoute: AuthLayoutDraftingTemplatesUuidRoute,
  AuthLayoutMultiReviewStartUuidRoute: AuthLayoutMultiReviewStartUuidRoute,
  AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute:
    AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute,
  AuthLayoutReviewStartUuidRoute: AuthLayoutReviewStartUuidRoute,
  AuthLayoutAgentsAgentUuidIndexRoute: AuthLayoutAgentsAgentUuidIndexRoute,
  AuthLayoutAgentsManageIndexRoute: AuthLayoutAgentsManageIndexRoute,
  AuthLayoutDocumentsFolderIndexRoute: AuthLayoutDocumentsFolderIndexRoute,
  AuthLayoutDraftingDraftsIndexRoute: AuthLayoutDraftingDraftsIndexRoute,
  AuthLayoutDraftingTemplatesIndexRoute: AuthLayoutDraftingTemplatesIndexRoute,
  AuthLayoutMultiReviewStartIndexRoute: AuthLayoutMultiReviewStartIndexRoute,
  AuthLayoutPlaybooksPlaybookUuidIndexRoute:
    AuthLayoutPlaybooksPlaybookUuidIndexRoute,
  AuthLayoutReviewStartIndexRoute: AuthLayoutReviewStartIndexRoute,
  AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute:
    AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute,
  AuthLayoutReportsWordDocumentUuidReportUuidRoute:
    AuthLayoutReportsWordDocumentUuidReportUuidRoute,
};

const AuthLayoutRouteWithChildren = AuthLayoutRoute._addFileChildren(
  AuthLayoutRouteChildren,
);

interface AuthRouteChildren {
  AuthLayoutRoute: typeof AuthLayoutRouteWithChildren;
  AuthAuthErrorRoute: typeof AuthAuthErrorRoute;
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLayoutRoute: AuthLayoutRouteWithChildren,
  AuthAuthErrorRoute: AuthAuthErrorRoute,
};

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren);

export interface FileRoutesByFullPath {
  '': typeof AuthLayoutRouteWithChildren;
  '/auth/error': typeof AuthAuthErrorRoute;
  '/': typeof AuthLayoutIndexRoute;
  '/anonymisation/$uuid': typeof AuthLayoutAnonymisationUuidRoute;
  '/discovery/$uuid': typeof AuthLayoutDiscoveryUuidRoute;
  '/multi-review/$uuid': typeof AuthLayoutMultiReviewUuidRoute;
  '/review/$uuid': typeof AuthLayoutReviewUuidRoute;
  '/review/new': typeof AuthLayoutReviewNewRoute;
  '/settings/profile': typeof AuthLayoutSettingsProfileRoute;
  '/settings/users': typeof AuthLayoutSettingsUsersRoute;
  '/translate': typeof AuthLayoutTranslateLayoutRouteWithChildren;
  '/anonymisation': typeof AuthLayoutAnonymisationIndexRoute;
  '/archive': typeof AuthLayoutArchiveIndexRoute;
  '/discovery': typeof AuthLayoutDiscoveryIndexRoute;
  '/multi-review': typeof AuthLayoutMultiReviewIndexRoute;
  '/playbooks': typeof AuthLayoutPlaybooksIndexRoute;
  '/review': typeof AuthLayoutReviewIndexRoute;
  '/agents/manage/$agentUuid': typeof AuthLayoutAgentsManageAgentUuidRoute;
  '/anonymisation/file/$uuid': typeof AuthLayoutAnonymisationFileUuidRoute;
  '/documents/file/$uuid': typeof AuthLayoutDocumentsFileUuidRoute;
  '/documents/folder/$uuid': typeof AuthLayoutDocumentsFolderUuidRoute;
  '/drafting/drafts/$uuid': typeof AuthLayoutDraftingDraftsUuidRoute;
  '/drafting/drafts/choose-template': typeof AuthLayoutDraftingDraftsChooseTemplateRoute;
  '/drafting/templates/$uuid': typeof AuthLayoutDraftingTemplatesUuidRoute;
  '/multi-review/start/$uuid': typeof AuthLayoutMultiReviewStartUuidRoute;
  '/playbooks/$playbookUuid/bulk-items': typeof AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute;
  '/review/start/$uuid': typeof AuthLayoutReviewStartUuidRoute;
  '/agents/$agentUuid': typeof AuthLayoutAgentsAgentUuidIndexRoute;
  '/agents/manage': typeof AuthLayoutAgentsManageIndexRoute;
  '/documents/folder': typeof AuthLayoutDocumentsFolderIndexRoute;
  '/drafting/drafts': typeof AuthLayoutDraftingDraftsIndexRoute;
  '/drafting/templates': typeof AuthLayoutDraftingTemplatesIndexRoute;
  '/multi-review/start': typeof AuthLayoutMultiReviewStartIndexRoute;
  '/playbooks/$playbookUuid': typeof AuthLayoutPlaybooksPlaybookUuidIndexRoute;
  '/review/start': typeof AuthLayoutReviewStartIndexRoute;
  '/translate/history': typeof AuthLayoutTranslateHistoryIndexRoute;
  '/agents/$agentUuid/conversations/$conversationUuid': typeof AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute;
  '/reports/word/$documentUuid/$reportUuid': typeof AuthLayoutReportsWordDocumentUuidReportUuidRoute;
  '/translate/files': typeof AuthLayoutTranslateLayoutFilesIndexRoute;
  '/translate/text': typeof AuthLayoutTranslateLayoutTextIndexRoute;
  '/translate/files/group/$uuid': typeof AuthLayoutTranslateLayoutFilesGroupUuidRoute;
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren;
  '/auth/error': typeof AuthAuthErrorRoute;
  '/': typeof AuthLayoutIndexRoute;
  '/anonymisation/$uuid': typeof AuthLayoutAnonymisationUuidRoute;
  '/discovery/$uuid': typeof AuthLayoutDiscoveryUuidRoute;
  '/multi-review/$uuid': typeof AuthLayoutMultiReviewUuidRoute;
  '/review/$uuid': typeof AuthLayoutReviewUuidRoute;
  '/review/new': typeof AuthLayoutReviewNewRoute;
  '/settings/profile': typeof AuthLayoutSettingsProfileRoute;
  '/settings/users': typeof AuthLayoutSettingsUsersRoute;
  '/translate': typeof AuthLayoutTranslateLayoutRouteWithChildren;
  '/anonymisation': typeof AuthLayoutAnonymisationIndexRoute;
  '/archive': typeof AuthLayoutArchiveIndexRoute;
  '/discovery': typeof AuthLayoutDiscoveryIndexRoute;
  '/multi-review': typeof AuthLayoutMultiReviewIndexRoute;
  '/playbooks': typeof AuthLayoutPlaybooksIndexRoute;
  '/review': typeof AuthLayoutReviewIndexRoute;
  '/agents/manage/$agentUuid': typeof AuthLayoutAgentsManageAgentUuidRoute;
  '/anonymisation/file/$uuid': typeof AuthLayoutAnonymisationFileUuidRoute;
  '/documents/file/$uuid': typeof AuthLayoutDocumentsFileUuidRoute;
  '/documents/folder/$uuid': typeof AuthLayoutDocumentsFolderUuidRoute;
  '/drafting/drafts/$uuid': typeof AuthLayoutDraftingDraftsUuidRoute;
  '/drafting/drafts/choose-template': typeof AuthLayoutDraftingDraftsChooseTemplateRoute;
  '/drafting/templates/$uuid': typeof AuthLayoutDraftingTemplatesUuidRoute;
  '/multi-review/start/$uuid': typeof AuthLayoutMultiReviewStartUuidRoute;
  '/playbooks/$playbookUuid/bulk-items': typeof AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute;
  '/review/start/$uuid': typeof AuthLayoutReviewStartUuidRoute;
  '/agents/$agentUuid': typeof AuthLayoutAgentsAgentUuidIndexRoute;
  '/agents/manage': typeof AuthLayoutAgentsManageIndexRoute;
  '/documents/folder': typeof AuthLayoutDocumentsFolderIndexRoute;
  '/drafting/drafts': typeof AuthLayoutDraftingDraftsIndexRoute;
  '/drafting/templates': typeof AuthLayoutDraftingTemplatesIndexRoute;
  '/multi-review/start': typeof AuthLayoutMultiReviewStartIndexRoute;
  '/playbooks/$playbookUuid': typeof AuthLayoutPlaybooksPlaybookUuidIndexRoute;
  '/review/start': typeof AuthLayoutReviewStartIndexRoute;
  '/translate/history': typeof AuthLayoutTranslateHistoryIndexRoute;
  '/agents/$agentUuid/conversations/$conversationUuid': typeof AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute;
  '/reports/word/$documentUuid/$reportUuid': typeof AuthLayoutReportsWordDocumentUuidReportUuidRoute;
  '/translate/files': typeof AuthLayoutTranslateLayoutFilesIndexRoute;
  '/translate/text': typeof AuthLayoutTranslateLayoutTextIndexRoute;
  '/translate/files/group/$uuid': typeof AuthLayoutTranslateLayoutFilesGroupUuidRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_auth': typeof AuthRouteWithChildren;
  '/_auth/_layout': typeof AuthLayoutRouteWithChildren;
  '/_auth/auth/error': typeof AuthAuthErrorRoute;
  '/_auth/_layout/': typeof AuthLayoutIndexRoute;
  '/_auth/_layout/anonymisation/$uuid': typeof AuthLayoutAnonymisationUuidRoute;
  '/_auth/_layout/discovery/$uuid': typeof AuthLayoutDiscoveryUuidRoute;
  '/_auth/_layout/multi-review/$uuid': typeof AuthLayoutMultiReviewUuidRoute;
  '/_auth/_layout/review/$uuid': typeof AuthLayoutReviewUuidRoute;
  '/_auth/_layout/review/new': typeof AuthLayoutReviewNewRoute;
  '/_auth/_layout/settings/profile': typeof AuthLayoutSettingsProfileRoute;
  '/_auth/_layout/settings/users': typeof AuthLayoutSettingsUsersRoute;
  '/_auth/_layout/translate': typeof AuthLayoutTranslateRouteWithChildren;
  '/_auth/_layout/translate/_layout': typeof AuthLayoutTranslateLayoutRouteWithChildren;
  '/_auth/_layout/anonymisation/': typeof AuthLayoutAnonymisationIndexRoute;
  '/_auth/_layout/archive/': typeof AuthLayoutArchiveIndexRoute;
  '/_auth/_layout/discovery/': typeof AuthLayoutDiscoveryIndexRoute;
  '/_auth/_layout/multi-review/': typeof AuthLayoutMultiReviewIndexRoute;
  '/_auth/_layout/playbooks/': typeof AuthLayoutPlaybooksIndexRoute;
  '/_auth/_layout/review/': typeof AuthLayoutReviewIndexRoute;
  '/_auth/_layout/agents/manage/$agentUuid': typeof AuthLayoutAgentsManageAgentUuidRoute;
  '/_auth/_layout/anonymisation/file/$uuid': typeof AuthLayoutAnonymisationFileUuidRoute;
  '/_auth/_layout/documents/file/$uuid': typeof AuthLayoutDocumentsFileUuidRoute;
  '/_auth/_layout/documents/folder/$uuid': typeof AuthLayoutDocumentsFolderUuidRoute;
  '/_auth/_layout/drafting/drafts/$uuid': typeof AuthLayoutDraftingDraftsUuidRoute;
  '/_auth/_layout/drafting/drafts/choose-template': typeof AuthLayoutDraftingDraftsChooseTemplateRoute;
  '/_auth/_layout/drafting/templates/$uuid': typeof AuthLayoutDraftingTemplatesUuidRoute;
  '/_auth/_layout/multi-review/start/$uuid': typeof AuthLayoutMultiReviewStartUuidRoute;
  '/_auth/_layout/playbooks/$playbookUuid/bulk-items': typeof AuthLayoutPlaybooksPlaybookUuidBulkItemsRoute;
  '/_auth/_layout/review/start/$uuid': typeof AuthLayoutReviewStartUuidRoute;
  '/_auth/_layout/agents/$agentUuid/': typeof AuthLayoutAgentsAgentUuidIndexRoute;
  '/_auth/_layout/agents/manage/': typeof AuthLayoutAgentsManageIndexRoute;
  '/_auth/_layout/documents/folder/': typeof AuthLayoutDocumentsFolderIndexRoute;
  '/_auth/_layout/drafting/drafts/': typeof AuthLayoutDraftingDraftsIndexRoute;
  '/_auth/_layout/drafting/templates/': typeof AuthLayoutDraftingTemplatesIndexRoute;
  '/_auth/_layout/multi-review/start/': typeof AuthLayoutMultiReviewStartIndexRoute;
  '/_auth/_layout/playbooks/$playbookUuid/': typeof AuthLayoutPlaybooksPlaybookUuidIndexRoute;
  '/_auth/_layout/review/start/': typeof AuthLayoutReviewStartIndexRoute;
  '/_auth/_layout/translate/history/': typeof AuthLayoutTranslateHistoryIndexRoute;
  '/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid': typeof AuthLayoutAgentsAgentUuidConversationsConversationUuidRoute;
  '/_auth/_layout/reports/word/$documentUuid/$reportUuid': typeof AuthLayoutReportsWordDocumentUuidReportUuidRoute;
  '/_auth/_layout/translate/_layout/files/': typeof AuthLayoutTranslateLayoutFilesIndexRoute;
  '/_auth/_layout/translate/_layout/text/': typeof AuthLayoutTranslateLayoutTextIndexRoute;
  '/_auth/_layout/translate/_layout/files/group/$uuid': typeof AuthLayoutTranslateLayoutFilesGroupUuidRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/auth/error'
    | '/'
    | '/anonymisation/$uuid'
    | '/discovery/$uuid'
    | '/multi-review/$uuid'
    | '/review/$uuid'
    | '/review/new'
    | '/settings/profile'
    | '/settings/users'
    | '/translate'
    | '/anonymisation'
    | '/archive'
    | '/discovery'
    | '/multi-review'
    | '/playbooks'
    | '/review'
    | '/agents/manage/$agentUuid'
    | '/anonymisation/file/$uuid'
    | '/documents/file/$uuid'
    | '/documents/folder/$uuid'
    | '/drafting/drafts/$uuid'
    | '/drafting/drafts/choose-template'
    | '/drafting/templates/$uuid'
    | '/multi-review/start/$uuid'
    | '/playbooks/$playbookUuid/bulk-items'
    | '/review/start/$uuid'
    | '/agents/$agentUuid'
    | '/agents/manage'
    | '/documents/folder'
    | '/drafting/drafts'
    | '/drafting/templates'
    | '/multi-review/start'
    | '/playbooks/$playbookUuid'
    | '/review/start'
    | '/translate/history'
    | '/agents/$agentUuid/conversations/$conversationUuid'
    | '/reports/word/$documentUuid/$reportUuid'
    | '/translate/files'
    | '/translate/text'
    | '/translate/files/group/$uuid';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/auth/error'
    | '/'
    | '/anonymisation/$uuid'
    | '/discovery/$uuid'
    | '/multi-review/$uuid'
    | '/review/$uuid'
    | '/review/new'
    | '/settings/profile'
    | '/settings/users'
    | '/translate'
    | '/anonymisation'
    | '/archive'
    | '/discovery'
    | '/multi-review'
    | '/playbooks'
    | '/review'
    | '/agents/manage/$agentUuid'
    | '/anonymisation/file/$uuid'
    | '/documents/file/$uuid'
    | '/documents/folder/$uuid'
    | '/drafting/drafts/$uuid'
    | '/drafting/drafts/choose-template'
    | '/drafting/templates/$uuid'
    | '/multi-review/start/$uuid'
    | '/playbooks/$playbookUuid/bulk-items'
    | '/review/start/$uuid'
    | '/agents/$agentUuid'
    | '/agents/manage'
    | '/documents/folder'
    | '/drafting/drafts'
    | '/drafting/templates'
    | '/multi-review/start'
    | '/playbooks/$playbookUuid'
    | '/review/start'
    | '/translate/history'
    | '/agents/$agentUuid/conversations/$conversationUuid'
    | '/reports/word/$documentUuid/$reportUuid'
    | '/translate/files'
    | '/translate/text'
    | '/translate/files/group/$uuid';
  id:
    | '__root__'
    | '/_auth'
    | '/_auth/_layout'
    | '/_auth/auth/error'
    | '/_auth/_layout/'
    | '/_auth/_layout/anonymisation/$uuid'
    | '/_auth/_layout/discovery/$uuid'
    | '/_auth/_layout/multi-review/$uuid'
    | '/_auth/_layout/review/$uuid'
    | '/_auth/_layout/review/new'
    | '/_auth/_layout/settings/profile'
    | '/_auth/_layout/settings/users'
    | '/_auth/_layout/translate'
    | '/_auth/_layout/translate/_layout'
    | '/_auth/_layout/anonymisation/'
    | '/_auth/_layout/archive/'
    | '/_auth/_layout/discovery/'
    | '/_auth/_layout/multi-review/'
    | '/_auth/_layout/playbooks/'
    | '/_auth/_layout/review/'
    | '/_auth/_layout/agents/manage/$agentUuid'
    | '/_auth/_layout/anonymisation/file/$uuid'
    | '/_auth/_layout/documents/file/$uuid'
    | '/_auth/_layout/documents/folder/$uuid'
    | '/_auth/_layout/drafting/drafts/$uuid'
    | '/_auth/_layout/drafting/drafts/choose-template'
    | '/_auth/_layout/drafting/templates/$uuid'
    | '/_auth/_layout/multi-review/start/$uuid'
    | '/_auth/_layout/playbooks/$playbookUuid/bulk-items'
    | '/_auth/_layout/review/start/$uuid'
    | '/_auth/_layout/agents/$agentUuid/'
    | '/_auth/_layout/agents/manage/'
    | '/_auth/_layout/documents/folder/'
    | '/_auth/_layout/drafting/drafts/'
    | '/_auth/_layout/drafting/templates/'
    | '/_auth/_layout/multi-review/start/'
    | '/_auth/_layout/playbooks/$playbookUuid/'
    | '/_auth/_layout/review/start/'
    | '/_auth/_layout/translate/history/'
    | '/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid'
    | '/_auth/_layout/reports/word/$documentUuid/$reportUuid'
    | '/_auth/_layout/translate/_layout/files/'
    | '/_auth/_layout/translate/_layout/text/'
    | '/_auth/_layout/translate/_layout/files/group/$uuid';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_layout",
        "/_auth/auth/error"
      ]
    },
    "/_auth/_layout": {
      "filePath": "_auth/_layout.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_layout/",
        "/_auth/_layout/anonymisation/$uuid",
        "/_auth/_layout/discovery/$uuid",
        "/_auth/_layout/multi-review/$uuid",
        "/_auth/_layout/review/$uuid",
        "/_auth/_layout/review/new",
        "/_auth/_layout/settings/profile",
        "/_auth/_layout/settings/users",
        "/_auth/_layout/translate",
        "/_auth/_layout/anonymisation/",
        "/_auth/_layout/archive/",
        "/_auth/_layout/discovery/",
        "/_auth/_layout/multi-review/",
        "/_auth/_layout/playbooks/",
        "/_auth/_layout/review/",
        "/_auth/_layout/agents/manage/$agentUuid",
        "/_auth/_layout/anonymisation/file/$uuid",
        "/_auth/_layout/documents/file/$uuid",
        "/_auth/_layout/documents/folder/$uuid",
        "/_auth/_layout/drafting/drafts/$uuid",
        "/_auth/_layout/drafting/drafts/choose-template",
        "/_auth/_layout/drafting/templates/$uuid",
        "/_auth/_layout/multi-review/start/$uuid",
        "/_auth/_layout/playbooks/$playbookUuid/bulk-items",
        "/_auth/_layout/review/start/$uuid",
        "/_auth/_layout/agents/$agentUuid/",
        "/_auth/_layout/agents/manage/",
        "/_auth/_layout/documents/folder/",
        "/_auth/_layout/drafting/drafts/",
        "/_auth/_layout/drafting/templates/",
        "/_auth/_layout/multi-review/start/",
        "/_auth/_layout/playbooks/$playbookUuid/",
        "/_auth/_layout/review/start/",
        "/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid",
        "/_auth/_layout/reports/word/$documentUuid/$reportUuid"
      ]
    },
    "/_auth/auth/error": {
      "filePath": "_auth/auth.error.tsx",
      "parent": "/_auth"
    },
    "/_auth/_layout/": {
      "filePath": "_auth/_layout/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/anonymisation/$uuid": {
      "filePath": "_auth/_layout/anonymisation/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/discovery/$uuid": {
      "filePath": "_auth/_layout/discovery/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/multi-review/$uuid": {
      "filePath": "_auth/_layout/multi-review/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/review/$uuid": {
      "filePath": "_auth/_layout/review/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/review/new": {
      "filePath": "_auth/_layout/review/new.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/settings/profile": {
      "filePath": "_auth/_layout/settings/profile.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/settings/users": {
      "filePath": "_auth/_layout/settings/users.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/translate": {
      "filePath": "_auth/_layout/translate",
      "parent": "/_auth/_layout",
      "children": [
        "/_auth/_layout/translate/_layout",
        "/_auth/_layout/translate/history/"
      ]
    },
    "/_auth/_layout/translate/_layout": {
      "filePath": "_auth/_layout/translate/_layout.tsx",
      "parent": "/_auth/_layout/translate",
      "children": [
        "/_auth/_layout/translate/_layout/files/",
        "/_auth/_layout/translate/_layout/text/",
        "/_auth/_layout/translate/_layout/files/group/$uuid"
      ]
    },
    "/_auth/_layout/anonymisation/": {
      "filePath": "_auth/_layout/anonymisation/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/archive/": {
      "filePath": "_auth/_layout/archive/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/discovery/": {
      "filePath": "_auth/_layout/discovery/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/multi-review/": {
      "filePath": "_auth/_layout/multi-review/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/playbooks/": {
      "filePath": "_auth/_layout/playbooks/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/review/": {
      "filePath": "_auth/_layout/review/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/agents/manage/$agentUuid": {
      "filePath": "_auth/_layout/agents/manage/$agentUuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/anonymisation/file/$uuid": {
      "filePath": "_auth/_layout/anonymisation/file/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/documents/file/$uuid": {
      "filePath": "_auth/_layout/documents/file/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/documents/folder/$uuid": {
      "filePath": "_auth/_layout/documents/folder/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/drafting/drafts/$uuid": {
      "filePath": "_auth/_layout/drafting/drafts/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/drafting/drafts/choose-template": {
      "filePath": "_auth/_layout/drafting/drafts/choose-template.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/drafting/templates/$uuid": {
      "filePath": "_auth/_layout/drafting/templates/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/multi-review/start/$uuid": {
      "filePath": "_auth/_layout/multi-review/start/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/playbooks/$playbookUuid/bulk-items": {
      "filePath": "_auth/_layout/playbooks/$playbookUuid/bulk-items.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/review/start/$uuid": {
      "filePath": "_auth/_layout/review/start/$uuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/agents/$agentUuid/": {
      "filePath": "_auth/_layout/agents/$agentUuid/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/agents/manage/": {
      "filePath": "_auth/_layout/agents/manage/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/documents/folder/": {
      "filePath": "_auth/_layout/documents/folder/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/drafting/drafts/": {
      "filePath": "_auth/_layout/drafting/drafts/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/drafting/templates/": {
      "filePath": "_auth/_layout/drafting/templates/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/multi-review/start/": {
      "filePath": "_auth/_layout/multi-review/start/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/playbooks/$playbookUuid/": {
      "filePath": "_auth/_layout/playbooks/$playbookUuid/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/review/start/": {
      "filePath": "_auth/_layout/review/start/index.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/translate/history/": {
      "filePath": "_auth/_layout/translate/history/index.tsx",
      "parent": "/_auth/_layout/translate"
    },
    "/_auth/_layout/agents/$agentUuid/conversations/$conversationUuid": {
      "filePath": "_auth/_layout/agents/$agentUuid/conversations/$conversationUuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/reports/word/$documentUuid/$reportUuid": {
      "filePath": "_auth/_layout/reports/word/$documentUuid/$reportUuid.tsx",
      "parent": "/_auth/_layout"
    },
    "/_auth/_layout/translate/_layout/files/": {
      "filePath": "_auth/_layout/translate/_layout/files/index.tsx",
      "parent": "/_auth/_layout/translate/_layout"
    },
    "/_auth/_layout/translate/_layout/text/": {
      "filePath": "_auth/_layout/translate/_layout/text/index.tsx",
      "parent": "/_auth/_layout/translate/_layout"
    },
    "/_auth/_layout/translate/_layout/files/group/$uuid": {
      "filePath": "_auth/_layout/translate/_layout/files/group/$uuid.tsx",
      "parent": "/_auth/_layout/translate/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
