import type { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { DialogProvider } from '@legalfly/ui/dialog';
import { Toaster } from '@legalfly/ui/toast';

import { AppRouter } from './AppRouter';
import { AuthProvider } from './AuthProvider';

const queryClient = new QueryClient();

export const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <DialogProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <AppRouter queryClient={queryClient} />
          <Toaster />
          {children}
        </DialogProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
};
