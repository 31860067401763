import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createFileRoute, Link } from '@tanstack/react-router';

import { getFileIcon } from '@legalfly/components/documents/helpers';
import { Button, IconButton } from '@legalfly/ui/button';
import { Card } from '@legalfly/ui/card';
import { Checkbox } from '@legalfly/ui/checkbox';
import { Icon } from '@legalfly/ui/icon';
import { List, ListItem } from '@legalfly/ui/list';
import { Text } from '@legalfly/ui/text';
import { withToasts } from '@legalfly/ui/toast';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { useDownloadTranslatedFiles } from 'components/translate/TranslateGroupResult';
import { routeRedirectWithFeatureFlag } from 'core/analytics';
import { useCreateDocumentsZip } from 'core/modules/documents';
import { documentsToasts } from 'core/modules/documents/toasts';
import { translateQueryOptions, useTranslatedFiles } from 'core/modules/translate';

export const Route = createFileRoute('/_auth/_layout/translate/history/')({
  beforeLoad: async ({ context }) =>
    routeRedirectWithFeatureFlag(context.queryClient, 'translate-file-agent'),
  component: RouteComponent,
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(translateQueryOptions.translatedFiles()),
});

function RouteComponent() {
  const { t } = useTranslation();

  const [selectedFileUuids, setSelectedFileUuids] = useState<string[]>([]);

  const { translatedFiles } = useTranslatedFiles();
  const { handleDownload } = useDownloadTranslatedFiles();
  const { createDocumentsZip } = useCreateDocumentsZip();

  const handleSelectFile = (fileId: string) => {
    setSelectedFileUuids((prev) =>
      prev.includes(fileId) ? prev.filter((id) => id !== fileId) : [...prev, fileId],
    );
  };

  const handleDownloadSelected = () => {
    if (selectedFileUuids.length === 1) {
      const file = translatedFiles.find((file) => file.uuid === selectedFileUuids[0])!;

      handleDownload({
        uuid: file.uuid,
        filename: `${file.document.name}_${file.targetLanguage}.${file.document.extension}`,
      });
    } else if (selectedFileUuids.length > 1) {
      const files = translatedFiles.filter((file) => selectedFileUuids.includes(file.uuid));
      setSelectedFileUuids([]);
      withToasts(
        createDocumentsZip({
          body: {
            anonymized: files.some((file) => file.anonymized),
            uuids: files.map((file) => file.document.uuid),
            includeTranslated: true,
          },
        }),
      )(documentsToasts.createDocumentsZip());
    }
  };

  return (
    <Content className='relative' innerClassName='overflow-auto'>
      <ContentHeader>
        <ContentHeader.Title>{t('translate.history.title')}</ContentHeader.Title>
        <div className='flex items-center justify-between'>
          <ContentHeader.SubTitle>{t('translate.history.subtitle')}</ContentHeader.SubTitle>

          {translatedFiles.length > 0 && (
            <Button
              onClick={handleDownloadSelected}
              disabled={selectedFileUuids.length === 0}
              size='sm'
            >
              {t('documents.actionBar.download')} ({selectedFileUuids.length})
            </Button>
          )}
        </div>
      </ContentHeader>

      {translatedFiles.length === 0 && (
        <Card className='flex w-full flex-col items-center justify-center p-6 text-center'>
          <Text variant='headingSemiBold' className='mb-2'>
            {t('translate.history.empty.title')}
          </Text>
          <Text variant='body' className='mb-4'>
            {t('translate.history.empty.subTitle')}
          </Text>
          <Button asChild>
            <Link to='/translate/files'>{t('translate.history.empty.action')}</Link>
          </Button>
        </Card>
      )}

      <List className='mb-4 overflow-auto'>
        {translatedFiles.map((file) => {
          const downloadFile = () =>
            handleDownload({
              uuid: file.uuid,
              filename: `${file.document.name}_${file.targetLanguage}.${file.document.extension}`,
            });

          return (
            <ListItem key={file.uuid} className='gap-2 px-0'>
              <Text
                as='label'
                htmlFor={`select-${file.uuid}`}
                className='flex flex-1 cursor-pointer items-center gap-2'
              >
                <Checkbox
                  id={`select-${file.uuid}`}
                  checked={selectedFileUuids.includes(file.uuid)}
                  onCheckedChange={() => handleSelectFile(file.uuid)}
                />
                {file.document.name}
              </Text>
              <div className='flex items-center gap-2 text-content-body-strong'>
                <div className='flex items-center gap-1'>
                  <Icon name='globe' size='xs' />
                  <Text variant='bodySmall'>{t(`language.${file.targetLanguage}`)}</Text>
                </div>

                <div className='flex items-center gap-1'>
                  <Icon name={file.anonymized ? 'eye-off' : 'eye'} />
                  <Text variant='bodySmall'>{t('translate.history.anonymised')}</Text>
                </div>

                <div className='flex items-center gap-1'>
                  <Icon name={getFileIcon(file.document.fileType)} size='xs' />
                  <Text variant='bodySmall'>{t(`documents.type.${file.document.fileType}`)}</Text>
                </div>

                <IconButton name='download-2' variant='tertiary' size='sm' onClick={downloadFile} />
              </div>
            </ListItem>
          );
        })}
      </List>
    </Content>
  );
}
