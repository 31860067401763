import { zot } from '@legalfly/utils/env';

const environments = ['local', 'development', 'staging', 'production'] as const;

export const schema = zot.object({
  auth0: zot.object({
    domain: zot.string(),
    clientId: zot.string(),
    audience: zot.string(),
    authId: zot.optional(zot.string()),
  }),
  posthog: zot.object({
    token: zot.string(),
  }),
  environment: zot.literal(environments),
  isLocal: zot.boolean(),
  isProduction: zot.boolean(),
});
