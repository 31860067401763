import { useTranslation } from 'react-i18next';
import type { HeaderContext } from '@tanstack/react-table';

import { type ApiDocument, DocumentStatus, DocumentType } from '@legalfly/api/documents';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button } from '@legalfly/ui/button';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { cn } from '@legalfly/ui/utils';

import { getDocumentIcon, getDocumentName } from '../../documents/helpers';

const Header = ({ column }: HeaderContext<ApiDocument, unknown>) => {
  const { t } = useTranslation('components');

  return (
    <Button
      variant='unstyled'
      className='flex items-center gap-2'
      onClick={() => {
        column.toggleSorting(column.getIsSorted() === 'asc');
        trackEvent(
          {
            action: 'click',
            category: 'documents',
            label: 'sort',
          },
          { direction: column.getIsSorted(), column: column.id },
        );
      }}
    >
      {t('documents.documentPicker.name')}
      <Icon name='arrow-up' className={cn(column.getIsSorted() === 'asc' && 'rotate-180')} />
    </Button>
  );
};

interface CellProps {
  document: ApiDocument;
  onRetryFile?: ({ uuid }: { uuid: ApiDocument['uuid'] }) => void;
}

const CellContent = ({ document, onRetryFile }: CellProps) => {
  const { t } = useTranslation('components');

  return (
    <div className='flex flex-1 items-center gap-2 overflow-hidden'>
      <Icon name={getDocumentIcon(document)} className='min-h-5 min-w-5 flex-shrink-0' />
      <div className='flex-1 overflow-hidden'>
        <div className='flex items-center overflow-hidden'>
          <span className='truncate'>{getDocumentName(document)}</span>
          {document.favorited && (
            <Icon
              name='star'
              size='xs'
              className='ms-2 flex-shrink-0 fill-icon-strongest stroke-icon-strongest'
              aria-label={t('documents.documentPicker.favorited')}
            />
          )}
        </div>
      </div>
      {typeof onRetryFile === 'function' &&
        document.type === DocumentType.FILE &&
        document.status === DocumentStatus.ERROR && (
          <div className='flex flex-shrink-0 items-center gap-2'>
            <Button
              variant='soft'
              className='px-2'
              onClick={() => onRetryFile({ uuid: document.uuid })}
              renderLeft={<Icon name='refresh-ccw-5' />}
            >
              <Text variant='caption'>{t('documents.upload.retry')}</Text>
            </Button>
            <Icon name='x-close' className='text-risk-fill-high' />
            <Text className='text-risk-fill-high'>{t('documents.upload.failed')}</Text>
          </div>
        )}
    </div>
  );
};

export const NameColumn = {
  Header,
  CellContent,
};
