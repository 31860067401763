import { useEffect } from 'react';
import deepmerge from '@fastify/deepmerge';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query';

import type { ApiDocument, ApiFolderContents, DocumentsApi } from '@legalfly/api/documents';
import { DocumentStatus } from '@legalfly/api/documents';

import { documentsQueryOptions } from './documentsQueryOptions';

const merge = deepmerge({ all: true });

export const createDocumentsModule = ({ documentsApi }: { documentsApi: DocumentsApi }) => {
  const queryOptions = documentsQueryOptions({ documentsApi });

  const useFolder = <TData = ApiFolderContents>({
    uuid,
    select,
    sortBy,
    sortOrder,
  }: {
    uuid?: ApiDocument['uuid'];
    select?: (data: ApiFolderContents) => TData;
    sortBy?: Parameters<DocumentsApi['getFolder']>[0]['query']['sortBy'];
    sortOrder?: Parameters<DocumentsApi['getFolder']>[0]['query']['sortOrder'];
  } = {}) => {
    const { data, isLoading, error } = useSuspenseQuery(
      queryOptions.folder({ uuid, select, query: { sortBy, sortOrder } }),
    );

    return {
      ...data,
      isLoading,
      error,
    };
  };

  const useFile = (uuid: ApiDocument['uuid']) => {
    const queryClient = useQueryClient();
    const { data, isLoading, error, refetch } = useSuspenseQuery(queryOptions.file(uuid));

    useEffect(() => {
      if (data?.status === DocumentStatus.ERROR) {
        queryClient.invalidateQueries(queryOptions.file(uuid));
      }
    }, [data?.status, queryClient, uuid]);

    return {
      file: data,
      isLoading,
      error,
      refetch,
    };
  };

  const useFiles = (uuids: ApiDocument['uuid'][]) => {
    const { data, isLoading, error } = useSuspenseQueries({
      queries: uuids.map((uuid) => queryOptions.file(uuid)),
      combine: (results) => {
        return {
          data: results.map((result) => result.data),
          isLoading: results.some((result) => result.isLoading),
          error: results.find((result) => result.error)?.error,
        };
      },
    });

    return {
      files: data,
      isLoading,
      error,
    };
  };

  const useDeleteFile = ({ folderUuid }: { folderUuid: ApiDocument['uuid'] | undefined }) => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'delete'],
      mutationFn: documentsApi.deleteFile,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryOptions.folder({ uuid: folderUuid }).queryKey.slice(0, 2),
        });
      },
    });

    return {
      deleteFile: mutateAsync,
      isLoading: isPending,
    };
  };

  const useFileSnippets = (uuid: ApiDocument['uuid']) => {
    const { data, refetch, isLoading, error } = useQuery(queryOptions.fileSnippets(uuid));

    return {
      snippets: data,
      refetch,
      isLoading,
      error,
    };
  };

  const useFileSummary = (uuid: ApiDocument['uuid']) => {
    const { data, isLoading, error } = useSuspenseQuery(queryOptions.fileSummary(uuid));

    return {
      summary: data,
      isLoading,
      error,
    };
  };

  const useCreateFile = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'create'],
      mutationFn: documentsApi.createFile,
      onSuccess: (newFile, { uuid }) => {
        queryClient.setQueriesData(
          {
            queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 2),
          },
          (data: ApiFolderContents | undefined) => {
            if (!data) {
              return {
                documents: [{ ...newFile.document }],
                folder: undefined,
              };
            }
            return {
              ...data,
              documents: [...data.documents, { ...newFile.document }],
            };
          },
        );

        queryClient.invalidateQueries({
          queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 2),
        });
      },
      onError: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.folder({ uuid }));
      },
    });

    return {
      createFile: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateFolder = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['folder', 'create'],
      mutationFn: documentsApi.createFolder,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries({
          queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 2),
        });
      },
    });

    return {
      createFolder: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteFolder = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['folder', 'delete'],
      mutationFn: documentsApi.deleteFolder,
      onSuccess: ({ parentUuid }, { uuid }) => {
        queryClient.removeQueries({
          queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 2),
        });
        queryClient.invalidateQueries({
          queryKey: queryOptions.folder({ uuid: parentUuid }).queryKey.slice(0, 2),
        });
      },
    });

    return {
      deleteFolder: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateDocumentEntity = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'entity', 'create'],
      mutationFn: documentsApi.createEntity,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.fileSnippets(uuid));
      },
    });

    return {
      createDocumentEntity: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateDocumentEntity = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'entity', 'update'],
      mutationFn: documentsApi.updateEntity,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.fileSnippets(uuid));
      },
    });

    return {
      updateDocumentEntity: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteDocumentEntity = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'entity', 'delete'],
      mutationFn: documentsApi.deleteEntity,
      onSuccess: (_, { uuid }) => {
        queryClient.invalidateQueries(queryOptions.fileSnippets(uuid));
      },
    });

    return {
      deleteDocumentEntity: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDownloadDocumentUrl = () => {
    const queryClient = useQueryClient();
    const { getFileDownloadUrl } = queryOptions;

    const getDownloadUrl = ({ uuid }: { uuid: ApiDocument['uuid'] }) => {
      return queryClient.fetchQuery(getFileDownloadUrl(uuid));
    };

    return { getDownloadUrl };
  };

  const useDownloadAnonymizedDocumentUrlViaWS = () => {
    const queryClient = useQueryClient();
    const { getAnonymizedFileDownloadUrl } = queryOptions;

    const getDownloadUrlViaWS = ({ uuid }: { uuid: ApiDocument['uuid'] }) => {
      return queryClient.fetchQuery(getAnonymizedFileDownloadUrl(uuid));
    };

    return { getDownloadUrlViaWS };
  };

  const useDropDocuments = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['folder', 'drop'],
      mutationFn: documentsApi.dropDocuments,
      onMutate: async ({ body: { uuid, sourceUuids } }) => {
        await queryClient.cancelQueries(queryOptions.folder({ uuid }));

        const previousCurrentFolder = queryClient.getQueryData(
          queryOptions.folder({ uuid }).queryKey,
        );

        queryClient.setQueriesData(
          { queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 1) },
          (old: ApiFolderContents | undefined) => {
            if (!old) return old;
            return {
              ...old,
              documents: old.documents.filter((doc) => !sourceUuids.includes(doc.uuid)),
            };
          },
        );

        return { previousCurrentFolder };
      },
      onError: (_, { body: { uuid } }, context) => {
        // reset the current folder
        queryClient.setQueryData(
          queryOptions.folder({ uuid }).queryKey,
          context?.previousCurrentFolder,
        );
      },
      onSuccess: (
        { success, failed },
        { body: { uuid, targetUuid } },
        { previousCurrentFolder },
      ) => {
        // Invalidate the target folder
        queryClient.invalidateQueries(queryOptions.folder({ uuid: targetUuid }));

        // Revert failed documents back to the original folder
        if (failed.length) {
          queryClient.setQueryData(queryOptions.folder({ uuid }).queryKey, (old) => {
            if (!old || !previousCurrentFolder) return old;
            return {
              ...old,
              documents: previousCurrentFolder.documents.filter(
                (doc) => !success.includes(doc.uuid),
              ),
            };
          });
        }
      },
    });

    return {
      dropDocuments: mutateAsync,
      isLoading: isPending,
    };
  };

  const useUpdateFolder = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['folder', 'entity', 'update'],
      mutationFn: documentsApi.updateFolder,
      onSuccess: (response) => {
        queryClient.setQueriesData(
          { queryKey: queryOptions.folder({ uuid: response.uuid }).queryKey.slice(0, 1) },
          (old: ApiFolderContents | undefined) => {
            if (!old) return old;
            return {
              ...old,
              folder: merge(response, old.folder),
            };
          },
        );
      },
    });

    return {
      updateFolder: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCountFolderDocuments = (params: { uuids: ApiDocument['uuid'][]; enabled: boolean }) => {
    const { data, isLoading, error } = useQuery(queryOptions.countFolderDocuments(params));

    return {
      numberOfDocuments: Number(data ?? 0),
      isLoading,
      error,
    };
  };

  const useRetryFile = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['file', 'retry'],
      mutationFn: documentsApi.retryFile,
      onSuccess: ({ document }, { uuid }) => {
        if (document.type === 'file') {
          queryClient.setQueryData(queryOptions.file(uuid).queryKey, (oldData) => ({
            ...oldData,
            ...document,
          }));
        }
      },
    });

    return {
      retryFile: mutateAsync,
      isLoading: isPending,
    };
  };

  const useDeleteDocuments = () => {
    const queryClient = useQueryClient();

    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['documents', 'delete'],
      mutationFn: documentsApi.deleteDocuments,
      onSuccess: ({ parentUuid }, { body: { fileUuids, folderUuids } }) => {
        fileUuids.forEach((uuid) => {
          queryClient.removeQueries({ queryKey: queryOptions.file(uuid).queryKey.slice(0, 2) });
        });
        folderUuids.forEach((uuid) => {
          queryClient.removeQueries({
            queryKey: queryOptions.folder({ uuid }).queryKey.slice(0, 2),
          });
        });
        queryClient.invalidateQueries({
          queryKey: queryOptions.folder({ uuid: parentUuid }).queryKey.slice(0, 2),
        });
      },
    });

    return {
      deleteDocuments: mutateAsync,
      isLoading: isPending,
    };
  };

  const useCreateDocumentsZip = () => {
    const { mutateAsync, isPending } = useMutation({
      mutationKey: ['documents', 'download', 'zip'],
      mutationFn: documentsApi.createDocumentsZip,
    });

    return {
      createDocumentsZip: mutateAsync,
      isLoading: isPending,
    };
  };

  return {
    useFolder,
    useFile,
    useFiles,
    useFileSnippets,
    useFileSummary,
    useCreateFile,
    useCreateFolder,
    useDeleteFile,
    useDeleteFolder,
    useCreateDocumentEntity,
    useUpdateDocumentEntity,
    useDeleteDocumentEntity,
    useDownloadDocumentUrl,
    useDownloadAnonymizedDocumentUrlViaWS,
    useDropDocuments,
    useUpdateFolder,
    useCountFolderDocuments,
    useRetryFile,
    useDeleteDocuments,
    useCreateDocumentsZip,
    documentsQueryOptions: queryOptions,
  };
};
