import type { Relation } from 'typeorm';
import {
    BaseEntity,
    ChildEntity,
    Column,
    CreateDateColumn,
    Entity,
    Generated,
    Index,
    JoinColumn,
    ManyToOne,
    PrimaryGeneratedColumn,
    TableInheritance,
    UpdateDateColumn,
} from 'typeorm';

import type {
    AgentPermissions,
    DiscoveryPermissions,
    DraftPermissions,
    MultiReviewPermissions,
    PermissionEntityType,
    PermissionOwnerType,
    ReviewPermissions,
    TemplatePermissions,
    UniquePermissions,
} from '@legalfly/api/core';
import { permissionConfig, permissionEntityTypes, uniquePermissions } from '@legalfly/api/core';
import type { Agent } from 'entity/agents/Agent';
import type { Template } from 'entity/drafting/Template';

import type { Draft } from './drafting/Draft';
import type { MultiReview } from './MultiReview';
import type { Review } from './Review';

@Index(['ownerType', 'ownerId'])
@Index(['type', 'typeId'])
@TableInheritance({ column: { type: 'enum', name: 'type', enum: permissionEntityTypes } })
@Entity()
export class Permission extends BaseEntity
{
    @PrimaryGeneratedColumn({ type: 'integer' })
    id: number;

    @Column({
        type: 'uuid',
        unique: true,
    })
    @Generated('uuid')
    uuid: string;

    @Column({
        type: 'enum',
        enum: permissionEntityTypes,
    })
    type: PermissionEntityType;

    @Column({
        type: 'integer',
    })
    typeId: number;

    @Column({
        type: 'enum',
        enum: uniquePermissions,
    })
    action: UniquePermissions;

    @Column({
        type: 'enum',
        enum: permissionConfig.owners,
    })
    ownerType: PermissionOwnerType;

    @Column({
        type: 'integer',
    })
    ownerId: number;

    @CreateDateColumn()
    createdAt: Date;

    @UpdateDateColumn()
    updatedAt: Date;
}

@PermissionChildEntity('agent')
export class PermissionTypeAgent extends Permission
{
    declare type: 'agent';
    declare action: AgentPermissions;

    @ManyToOne('Agent', 'permissions', {
        onDelete: 'CASCADE',
        createForeignKeyConstraints: false,
    })
    @JoinColumn({ name: 'typeId' })
    agent: Relation<Agent>;
}

@PermissionChildEntity('discovery')
export class PermissionTypeDiscovery extends Permission
{
    declare type: 'discovery';
    declare action: DiscoveryPermissions;
}

@PermissionChildEntity('draft')
export class PermissionTypeDraft extends Permission
{
    declare type: 'draft';
    declare action: DraftPermissions;

    @ManyToOne('Review', 'permissions', {
        onDelete: 'CASCADE',
        createForeignKeyConstraints: false,
    })
    @JoinColumn({ name: 'typeId' })
    draft: Relation<Draft>;
}

@PermissionChildEntity('multiReview')
export class PermissionTypeMultiReview extends Permission
{
    declare type: 'multiReview';
    declare action: MultiReviewPermissions;

    @ManyToOne('MultiReview', 'permissions', {
        onDelete: 'CASCADE',
        createForeignKeyConstraints: false,
    })
    @JoinColumn({ name: 'typeId' })
    multiReview: Relation<MultiReview>;
}

@PermissionChildEntity('review')
export class PermissionTypeReview extends Permission
{
    declare type: 'review';
    declare action: ReviewPermissions;

    @ManyToOne('Review', 'permissions', {
        onDelete: 'CASCADE',
        createForeignKeyConstraints: false,
    })
    @JoinColumn({ name: 'typeId' })
    review: Relation<Review>;
}

@PermissionChildEntity('template')
export class PermissionTypeTemplate extends Permission
{
    declare type: 'template';
    declare action: TemplatePermissions;

    @ManyToOne('Template', 'permissions', {
        onDelete: 'CASCADE',
        createForeignKeyConstraints: false,
    })
    @JoinColumn({ name: 'typeId' })
    template: Relation<Template>;
}

/**
 * This wrapper ensures that the ChildEntity decorator only receives PermissionEntityType values.
 */
function PermissionChildEntity<T extends PermissionEntityType>(type: T)
{
    return function (constructor: new (...args: any[]) => Permission)
    {
        ChildEntity(type)(constructor);
        constructor.prototype.type = type;
    };
}
