import { type PropsWithChildren, useEffect, useState } from 'react';

import { initI18n } from '@legalfly/i18n';
import { initAnalytics } from '@legalfly/reporting/tracking';
import { LegalFlyLoader } from '@legalfly/ui/loader';
import { env } from 'core/env';

initAnalytics({
  environment: env.environment,
  token: env.posthog.token,
  project: 'webapp',
});

const AppInitializer = ({ children }: PropsWithChildren) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      initI18n({
        defaultNS: 'frontend',
        ns: ['frontend', 'components'],
      }).then(() => {
        setIsInitialized(true);
      });
    }
  }, [isInitialized]);

  return isInitialized ? children : <LegalFlyLoader showSpinner={false} />;
};

export default AppInitializer;
