import { forwardRef, type HTMLAttributes } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../../utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
}

const ListItem = forwardRef<HTMLDivElement, Props>(
  ({ asChild, className, onClick, ...props }, ref) => {
    const Comp = asChild ? Slot : 'div';

    return (
      <Comp
        tabIndex={onClick ? 0 : -1}
        role='listitem'
        onKeyDown={(e) => {
          if (onClick && (e.key === 'Enter' || e.key === ' ')) {
            e.currentTarget.click();
          }
        }}
        className={cn(
          'flex items-center justify-between border-t border-stroke-weaker px-5 py-3',
          onClick &&
            'cursor-pointer hover:bg-fill-hover-weak focus-visible:outline focus-visible:outline-1 focus-visible:-outline-offset-8 focus-visible:outline-stroke-weak active:bg-fill-pressed-weak',
          className,
        )}
        ref={ref}
        onClick={onClick}
        {...props}
      />
    );
  },
);

ListItem.displayName = 'ListItem';

export { ListItem };
