import { memo } from "react";

import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@legalfly/ui/tooltip';

interface NavigationTooltipProps {
  children: React.ReactNode;
  text: string;
  isExpanded: boolean;
}

export const NavigationTooltip = memo(({ children, text, isExpanded }: NavigationTooltipProps) => {
  if (isExpanded) {
    return children;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side='right' sideOffset={8}>
            <span className='text-body-small'>{text}</span>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
});

NavigationTooltip.displayName = 'NavigationTooltip';
