import { useTranslation } from 'react-i18next';
import { useForm } from '@tanstack/react-form';

import type { ApiConfigUserSettings } from '@legalfly/api/config';
import { SelectJurisdiction } from '@legalfly/components/jurisdiction/SelectJurisdiction';
import { SelectLanguage } from '@legalfly/components/language/SelectLanguage';
import { Button } from '@legalfly/ui/button';
import { Divider } from '@legalfly/ui/divider';
import { Icon } from '@legalfly/ui/icon';
import { Text } from '@legalfly/ui/text';
import { useIsDarkMode } from '@legalfly/ui/theme';
import { withToasts } from '@legalfly/ui/toast';
import { cn } from '@legalfly/ui/utils';
import { settingsToasts, useUpdateUserSettings } from 'core/modules/settings';
import { changeTheme } from 'core/theme';

interface Props {
  userSettings: ApiConfigUserSettings;
}

const FormItem = ({
  label,
  subtitle,
  children,
}: {
  label: string;
  subtitle: string;
  children: React.ReactNode;
}) => {
  return (
    <div className='flex items-start justify-center gap-4'>
      <div className='flex-1'>
        <Text variant='bodyLight'>{label}</Text>
        <Text variant='bodyLight' className='text-content-body-strong'>
          {subtitle}
        </Text>
      </div>
      <div className='flex-1'>{children}</div>
    </div>
  );
};

export const OnboardingForm = ({ userSettings }: Props) => {
  const { i18n, t } = useTranslation();
  const isDarkMode = useIsDarkMode();
  const { updateUserSettings } = useUpdateUserSettings();

  const defaultValues = {
    language: userSettings.language ?? i18n.language,
    jurisdiction: userSettings.jurisdiction,
  };

  type FormData = typeof defaultValues;

  const form = useForm<FormData>({
    defaultValues,
    onSubmit: async ({ value }) => {
      await withToasts(
        updateUserSettings({
          isOnboardingCompleted: true,
          jurisdiction: value.jurisdiction,
          language: value.language,
        }),
      )(settingsToasts.updateUserSettings());
    },
  });

  const handleSetLightMode = () => {
    if (isDarkMode) {
      changeTheme();
    }
  };

  const handleSetDarkMode = () => {
    if (!isDarkMode) {
      changeTheme();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      className='flex flex-col gap-6'
    >
      <Text variant='bodyLight' className='text-content-body-strong'>
        {t('form.onboarding.title')}
      </Text>
      <Divider variant='weak' />

      <FormItem label={t('form.language.label')} subtitle={t('form.language.subtitle')}>
        <form.Field name='language'>
          {(field) => (
            <SelectLanguage
              value={field.state.value}
              onChange={field.handleChange}
              triggerProps={{ className: 'py-2' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>
      <Divider variant='weak' />

      <FormItem label={t('form.jurisdiction.label')} subtitle={t('form.jurisdiction.subtitle')}>
        <form.Field name='jurisdiction'>
          {(field) => (
            <SelectJurisdiction
              value={field.state.value}
              onChange={field.handleChange}
              triggerProps={{ className: 'py-2 w-full' }}
              contentProps={{ align: 'end' }}
            />
          )}
        </form.Field>
      </FormItem>
      <Divider variant='weak' />

      <FormItem label={t('form.theme.label')} subtitle={t('form.theme.subtitle')}>
        <div className='flex justify-end'>
          <Button
            renderLeft={<Icon name='sun' />}
            variant='soft'
            size='sm'
            className={cn('mr-3', {
              'bg-navigation-fill-press': !isDarkMode,
            })}
            onClick={handleSetLightMode}
          >
            {t('label.light')}
          </Button>
          <Button
            renderLeft={<Icon name='moon-02' />}
            variant='soft'
            size='sm'
            onClick={handleSetDarkMode}
            className={cn({
              'bg-fill-focus-strong': isDarkMode,
            })}
          >
            {t('label.dark')}
          </Button>
        </div>
      </FormItem>
      <Divider variant='weak' />

      <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <Button
            type='submit'
            disabled={!canSubmit}
            isLoading={isSubmitting}
            className='self-start'
          >
            {t('action.onboarding')}
          </Button>
        )}
      </form.Subscribe>
    </form>
  );
};
