import { useTranslation } from 'react-i18next';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { useSortableTable } from 'common/hooks/useSortableTable';
import { createSortableRoute } from 'common/utils/routeUtils';

import { DEFAULT_MULTI_REVIEW_SORT, type MultiReviewSortField } from '@legalfly/api/multi-reviews';
import { trackEvent } from '@legalfly/reporting/tracking';
import { Button, UploadButton } from '@legalfly/ui/button';
import { DataTable } from '@legalfly/ui/data-table/DataTable';
import { SearchInputForm } from '@legalfly/ui/form';
import { Icon } from '@legalfly/ui/icon';
import { useTableHeight } from '@legalfly/utils/hooks/useTableHeight';
import { ContentDropZone } from 'components/common/content/ContentDropZone';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { ContentSidebar } from 'components/common/content/ContentSidebar';
import { DropZone } from 'components/common/upload/DropZone';
import { MultiReviewsInsights } from 'components/multi-reviews/MultiReviewsInsights';
import { useMultiReviewColumns } from 'components/multi-reviews/useMultiReviewColumns';
import { useCreateFiles } from 'core/modules/documents/helpers/useCreateFiles';
import { multiReviewsQueryOptions, useMultiReviews } from 'core/modules/multi-reviews';
import { playbookQueryOptions } from 'core/modules/playbooks';

const { validateSearch, loaderDeps } = createSortableRoute<MultiReviewSortField>({
  defaultSort: DEFAULT_MULTI_REVIEW_SORT,
});

export const Route = createFileRoute('/_auth/_layout/multi-review/')({
  component: MultiReviewOverviewRoute,
  validateSearch,
  loaderDeps,
  loader({ context, deps: { sortBy, sortOrder } }) {
    return Promise.all([
      context.queryClient.ensureQueryData(playbookQueryOptions.playbooks()),
      context.queryClient.fetchQuery(multiReviewsQueryOptions.multiReviews({ sortBy, sortOrder })),
    ]);
  },
});

function MultiReviewOverviewRoute() {
  const { t } = useTranslation();
  const navigate = Route.useNavigate();
  const columns = useMultiReviewColumns();
  const sorting = Route.useSearch();

  const { currentSorting, onSort } = useSortableTable<MultiReviewSortField>({
    sorting,
    defaultSortField: DEFAULT_MULTI_REVIEW_SORT.sortBy,
    navigate,
  });

  const { multiReviews, stats } = useMultiReviews({
    sortBy: sorting.sortBy,
    sortOrder: sorting.sortOrder,
  });
  const { tableHeight, tableRef } = useTableHeight();

  const { createFiles, isCreatingFiles } = useCreateFiles('multi-review');
  const { filteredItems: filteredMultiReviews, setKeyword } = useKeywordFilter(multiReviews, [
    'name',
  ]);

  const uploadFiles = async (files: File[]) => {
    const results = await createFiles({ uuid: undefined, files });

    if (results.length) {
      navigate({
        to: '/multi-review/start',
      });
    }
  };

  const hasReviews = multiReviews.length > 0;

  return (
    <div className='flex gap-3'>
      <ContentDropZone
        parentClassName='flex-1'
        onDropFiles={(files: File[]) => {
          trackEvent({
            action: 'drop',
            category: 'multiReview',
          });
          uploadFiles(files);
        }}
        disabled={!hasReviews}
      >
        <ContentHeader>
          <ContentHeader.Title>{t('multiReview.title')}</ContentHeader.Title>
          <ContentHeader.SubTitle>{t('multiReview.subtitle')}</ContentHeader.SubTitle>
          <ContentHeader.Actions>
            <SearchInputForm
              placeholder={t('label.search')}
              onKeywordChange={setKeyword}
              onBlur={() =>
                trackEvent({
                  action: 'blur',
                  category: 'multiReview',
                  label: 'search',
                })
              }
            />
            <Link to='/multi-review/start' disabled={isCreatingFiles}>
              <Button renderLeft={<Icon name='folder' />} disabled={isCreatingFiles} variant='soft'>
                {t('multiReview.actions.select')}
              </Button>
            </Link>
            <UploadButton
              onUpload={uploadFiles}
              renderLeft={<Icon name='upload-1' />}
              isLoading={isCreatingFiles}
              onClick={() =>
                trackEvent({
                  action: 'click',
                  category: 'multiReview',
                  label: 'upload',
                })
              }
            >
              {t('action.uploadOrDrop')}
            </UploadButton>
          </ContentHeader.Actions>
        </ContentHeader>

        {hasReviews ? (
          <DataTable
            data={filteredMultiReviews}
            columns={columns}
            ref={tableRef}
            tableHeight={tableHeight}
            sorting={currentSorting}
            onSort={onSort}
            noResultsLabel={t('label.noResults')}
          />
        ) : (
          <DropZone
            onDropFiles={(files: File[]) => {
              trackEvent({
                action: 'drop',
                category: 'multiReview',
              });
              uploadFiles(files);
            }}
            withBackground
            renderActions={() => (
              <div className='flex flex-col gap-3'>
                <UploadButton
                  onUpload={uploadFiles}
                  renderLeft={<Icon name='upload-1' />}
                  onClick={() =>
                    trackEvent({
                      action: 'click',
                      category: 'multiReview',
                      label: 'upload',
                    })
                  }
                >
                  {t('multiReview.actions.upload')}
                </UploadButton>
                <Button variant='soft' renderLeft={<Icon name='folder' />} asChild>
                  <Link to='/multi-review/start'>{t('multiReview.actions.select')}</Link>
                </Button>
              </div>
            )}
          />
        )}
      </ContentDropZone>
      {hasReviews && (
        <ContentSidebar>
          <MultiReviewsInsights stats={stats} />
        </ContentSidebar>
      )}
    </div>
  );
}
