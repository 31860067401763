import { useTranslation } from 'react-i18next';
import type { SearchSchemaInput } from '@tanstack/react-router';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useKeywordFilter } from 'common/hooks/useKeywordFilter';
import { fromTanstackSorting, toTanstackSorting } from 'common/utils/sorting';

import type { BaseSorting, SortOrderType } from '@legalfly/api/core';
import { SortOrder } from '@legalfly/api/core';
import type { PlaybookSortField } from '@legalfly/api/playbooks';
import { DEFAULT_PLAYBOOK_SORT, PlaybookStatusEnum } from '@legalfly/api/playbooks';
import { trackEvent } from '@legalfly/reporting/tracking';
import { DataTable } from '@legalfly/ui/data-table/DataTable';
import { SearchInputForm } from '@legalfly/ui/form';
import { Tabs, TabsList, TabsTrigger } from '@legalfly/ui/tabs';
import { useTableHeight } from '@legalfly/utils/hooks/useTableHeight';
import { Content } from 'components/common/content/Content';
import { ContentHeader } from 'components/common/content/ContentHeader';
import { CreatePlaybookDialog } from 'components/playbooks/create/CreatePlaybookDialog';
import { PlaybookListEmpty } from 'components/playbooks/list/PlaybookListEmpty';
import { usePlaybookColumns } from 'components/playbooks/usePlaybookColumns';
import { playbookQueryOptions, usePlaybooks } from 'core/modules/playbooks';

type Sorting = BaseSorting<PlaybookSortField>;

export const Route = createFileRoute('/_auth/_layout/playbooks/')({
  validateSearch: (
    search: {
      status?: PlaybookStatusEnum;
      sortBy?: PlaybookSortField;
      sortOrder?: SortOrderType;
    } & SearchSchemaInput,
  ) => {
    const sorting: Sorting = {
      sortBy: search.sortBy ?? DEFAULT_PLAYBOOK_SORT.sortBy,
      sortOrder:
        search.sortOrder && SortOrder.includes(search.sortOrder)
          ? search.sortOrder
          : DEFAULT_PLAYBOOK_SORT.sortOrder,
    };

    return {
      status: search?.status,
      sortBy: sorting.sortBy,
      sortOrder: sorting.sortOrder,
    };
  },
  component: PlaybooksOverviewRoute,
  loaderDeps: ({ search: { status, sortBy, sortOrder } }) => ({ status, sortBy, sortOrder }),
  loader({ context, deps: { status, sortBy, sortOrder } }) {
    return context.queryClient.ensureQueryData(
      playbookQueryOptions.playbooks({
        status,
        sortBy,
        sortOrder,
      }),
    );
  },
});

function PlaybooksOverviewRoute() {
  const { t } = useTranslation();
  const { status, ...sorting } = Route.useSearch();
  const navigate = Route.useNavigate();

  const { tableHeight, tableRef } = useTableHeight();
  const columns = usePlaybookColumns();

  const { playbooks } = usePlaybooks({ status, ...sorting });
  const { filteredItems: filteredPlaybooks, setKeyword } = useKeywordFilter(playbooks, 'name');

  return (
    <Content className='overflow-visible'>
      <ContentHeader>
        <ContentHeader.Title>{t('nav.playbooks')}</ContentHeader.Title>
        <ContentHeader.SubTitle>{t('playbooks.overview.description')}</ContentHeader.SubTitle>
        <Tabs defaultValue={status ?? 'all'}>
          <TabsList className='mt-6 w-auto bg-transparent p-0'>
            <TabsTrigger
              value='all'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks'>{t('playbooks.tabs.all')}</Link>
            </TabsTrigger>
            <TabsTrigger
              value='private'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks' search={{ status: PlaybookStatusEnum.PRIVATE }}>
                {t('playbooks.tabs.private')}
              </Link>
            </TabsTrigger>
            <TabsTrigger
              value='public'
              asChild
              className='flex-grow-0 border-b-2 border-transparent px-6 data-[state=active]:border-stroke-weak'
            >
              <Link to='/playbooks' search={{ status: PlaybookStatusEnum.PUBLIC }}>
                {t('playbooks.tabs.company')}
              </Link>
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <ContentHeader.Actions>
          <SearchInputForm
            placeholder={t('label.search')}
            onKeywordChange={setKeyword}
            onBlur={() =>
              trackEvent({
                action: 'blur',
                category: 'playbooks',
                label: 'search',
              })
            }
          />
          <CreatePlaybookDialog />
        </ContentHeader.Actions>
      </ContentHeader>

      {playbooks.length ? (
        <DataTable
          ref={tableRef}
          columns={columns}
          data={filteredPlaybooks}
          tableHeight={tableHeight}
          sorting={toTanstackSorting(sorting)}
          onSort={(newSorting) => {
            const nextSorting = fromTanstackSorting<PlaybookSortField>(
              typeof newSorting === 'function'
                ? newSorting(toTanstackSorting(sorting))
                : newSorting,
              'createdAt',
            );
            navigate({
              search: (prev) => ({
                ...prev,
                sortBy: nextSorting.sortBy,
                sortOrder: nextSorting.sortOrder,
              }),
              replace: true,
            });
          }}
          noResultsLabel={t('label.noResults')}
        />
      ) : (
        <PlaybookListEmpty />
      )}
    </Content>
  );
}
